import React, { useState } from "react";
import Header from "../components/header";
import "./Login.css";
import firebase from "../firebase";

const auth = firebase.auth;

export default function Login({ history }) {
  const [nickname, setNickname] = useState("");

  const handleChange = (nickname) => {
    setNickname(nickname);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Login anonymously
    const anonLogin = await auth.signInAnonymously();

    // Set nickname to firebase auth
    await auth.currentUser
      .updateProfile({
        displayName: nickname,
      })
      .catch(function (error) {
        console.log(error);
      });

    // Add user data to firebase "players" collection

    await firebase.db.collection("players").add({
      createdAt: new Date(),
      uid: anonLogin.user.uid,
      nickname: nickname,
      games: [],
    });

    // Send to home

    // history.push("/");
  };

  return (
    <>
      <Header />
      <div>
        <div className="Name-content">
          <h1 className="Name-title">What's your name?</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              className="Name-input"
              placeholder={"Pick something cool..."}
              maxLength="10"
              onChange={(e) => handleChange(e.target.value)}
            />
            <br />
            <br />
            <button
              className="Name-button"
              type="submit"
              onSubmit={handleSubmit}
            >
              Save
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
