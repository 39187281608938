import React, { Component } from "react";
import TemplatePage from "./templatePage";
import "./styles/helpPage.css";
import cardgame from "../assets/cardgame.png";
class HelpPage extends Component {
  render() {
    return (
      <TemplatePage
        content={
          <div className="help-content">
            <div>
              <h2>Welcome</h2>
              Ahoy, ye scallywag! You have taken the helm of a pirate ship in a
              race for doubloons. Assemble your crew, shake out those sea legs,
              and batten down the hatches...a life of pillage and plunder
              creates a few enemies at the table. Savvy?
              <p>
                Get the tabletop version of{" "}
                <a href="https://playdoubloons.com">Doubloons</a> and experience
                the action packed card game come to life in person with your
                friends and family.
              </p>
              <img src={cardgame} alt="Doubloons" width="100%" />
              <br></br>
              <br></br>
              <h2>Game Rules</h2>
              <p>
                Be the first player to reach at least 7 doubloons and have the
                most at the end of a round. (A round starts with the player who
                went first in the game and ends with the player who went last)
              </p>
              <p>
                <strong>1. Draw a card.</strong> You can’t play action cards or
                use pirate effects before drawing. Then do the following in any
                order...
              </p>
              <p>
                <strong>
                  2. Play <em>up to</em> 3 action cards
                </strong>{" "}
                from your hand. When you play a card, place it front of you and
                follow the instructions on the card.{" "}
              </p>
              <p>
                <strong>3. Hire pirates onto your ship.</strong> You can hire
                any of the face-up pirates onto your ship by paying the cost
                indicated in the upper left. Place your payment in the doubloon
                pile.{" "}
              </p>
              <p>
                <strong>4. End your turn</strong> by moving your played action
                cards into the shared discard pile.
              </p>
              <br />
            </div>
          </div>
        }
      />
    );
  }
}

export default HelpPage;
