import React, { useState } from "react";
import _ from "lodash";

import PlayerMoves from "../playerMoves";
import Sidebar from "../sidebar";
import Modal from "react-modal";
import PlayerSection from "./PlayerSection";
import CardContainer, { CARDS_DISPLAY_SPLAY } from "./CardContainer";
import { CARD_TYPE_PIRATE } from "./Card";
import {
  PLAYER_SELECT,
  CARD_SELECT,
  PLAY,
  useInterfaceReducer,
  PIRATE_HIRE,
} from "./store";
import deriveUiState from "./store/deriveUiState";
import { applyCardEffectReducer } from "../../game/cardEffects";
import firebase from "../../firebase";

import "./Board.scss";
import "../styles/tutorial.css";
import "../styles/settings.css";
import "../styles/sidebar.css";
import "../styles/button.css";
import logo from "../../assets/logo.png";

import { AI_PLAYER_ID, BOARD_STATES, CARD_ID_TO_TYPE } from "../../constants";
import Tour from "reactour";
import Volume from "./Volume";
import { Link } from "react-router-dom";
import { getAndPlayAction } from "./ai";
import {
  blockPlayIfTooManyPirates,
  // unblockPlayIfPossible,
} from "./blockPlayIfTooManyPirates";

const steps = [
  {
    selector: ".tour-first-step",
    content: () => (
      <div>
        <h1>
          <span role="img" aria-label="waving hand">
            {" "}
            👋
          </span>{" "}
          Ahoy, matey!
        </h1>
        You have taken the helm of a pirate ship in a race for treasure.
        <br></br>
        <br></br>
        To win, you need
        <strong> to be the first player to earn 7 doubloons</strong>. You get
        doubloons by playing action cards and pirate cards.
      </div>
    ),
    style: {
      backgroundColor: "#4A1B14",
    },
  },
  {
    selector: ".tour-second-step",
    content: () => (
      <div>
        <h1>
          <span role="img" aria-label="anchor">
            ⚓
          </span>{" "}
          Start a Turn
        </h1>
        These are your cards.
        <ul>
          <li> You can play up to 3 action cards per turn. </li>
          <li>Playable cards have a green outline. Hover over to zoom.</li>
          <li>Click a card to select it. Then click "Play."</li>
          <li>
            At the beginning of your turn, you <strong>automatically</strong>{" "}
            draw 1 action card.
          </li>
        </ul>
      </div>
    ),
    style: {
      backgroundColor: "#4A1B14",
    },
  },
  {
    selector: ".tour-third-step",
    content: () => (
      <div>
        <h1>
          <span role="img" aria-label="joker card">
            🃏
          </span>{" "}
          Played Actions
        </h1>
        When you play your 3 action cards, they'll go here. Other players can
        see what cards you play. Think carefully about the order you play your
        cards to land the best combos.
      </div>
    ),
    style: {
      backgroundColor: "#4A1B14",
    },
  },
  {
    selector: ".tour-fourth-step",
    content: () => (
      <div>
        <h1>
          <span role="img" aria-label="pirate flag">
            🏴‍☠️
          </span>{" "}
          Hire Pirates
        </h1>
        You can hire these pirates into your crew at any point on your turn.
        <ul>
          <li>Hire pirates by paying the cost on the card.</li>
          <li>If you can afford a pirate, it will have a green outline.</li>
          <li>Click a pirate once to select it. Then click "Hire."</li>
          <li>
            You can have <strong>two pirates at a time.</strong> If you hire a
            third, you'll be asked to discard one.
          </li>
          <li>
            You can{" "}
            <strong>use each of your pirate's effects once per turn.</strong>
          </li>
        </ul>
      </div>
    ),
    style: {
      backgroundColor: "#4A1B14",
    },
    position: "left",
  },
  {
    selector: ".tour-fifth-step",
    content: () => (
      <div>
        <h1>
          <span role="img" aria-label="skip button">
            ⏭
          </span>{" "}
          Ending your turn
        </h1>
        When it's your turn, this will be a gold "End turn" button. Click it
        when you're done.
        <br></br>
        <br></br>
        If a card requires you to do something, you'll also see instructions
        here.
      </div>
    ),

    style: {
      backgroundColor: "#4A1B14",
    },
  },
  {
    selector: ".tour-sixth-step",
    content: () => (
      <div>
        <h1>
          <span role="img" aria-label="scroll">
            📜
          </span>{" "}
          Move Log
        </h1>
        Click this to see a log of every player's moves.
      </div>
    ),
    style: {
      backgroundColor: "#4A1B14",
    },
  },
  {
    selector: ".tour-seventh-step",
    content: () => (
      <div>
        <h1>
          <span role="img" aria-label="swords">
            ⚔️
          </span>{" "}
          Time for battle
        </h1>
        You can re-open this tutorial by clicking here. Click outside of this
        box to close. <br></br>
        <br></br>
      </div>
    ),
    style: {
      backgroundColor: "#4A1B14",
    },
  },
];

const getExecutionMessage = (state) => {
  const { selectedCardId, chainedSelectedCardId } = state;
  const execution = state.executionQueue[0];

  const cardName =
    CARD_ID_TO_TYPE[chainedSelectedCardId] || CARD_ID_TO_TYPE[selectedCardId];

  switch (execution?.state) {
    case BOARD_STATES.READY:
      return "";
    case BOARD_STATES.WAIT_FOR_HIRE:
      return `Press "Hire" to hire ${cardName}`;
    case BOARD_STATES.WAIT_FOR_SELL:
      return `Choose a pirate to discard`;
    case BOARD_STATES.WAIT_FOR_PLAY:
      return `Press "Play" to play ${cardName}`;
    case BOARD_STATES.WAIT_FOR_PLAYER_SELECT:
      return `Select a player to use ${cardName} on`;
    case BOARD_STATES.WAIT_FOR_ACTION_SELECT:
      return `Select a card to use ${cardName} on`;
    case BOARD_STATES.WAIT_FOR_PIRATE_SELECT:
      return `Select a pirate to use ${cardName} on`;
    case BOARD_STATES.WAIT_FOR_BOARD_SELECT:
      return `Select one of your played cards to use ${cardName} on`;
    default:
      return "Select a card";
  }
};

const removeItemOnce = (arr, value) => {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export default function Board({ game, me, gamePlayers, currentPlayer }) {
  const [isWinOpen, setIsWinOpen] = useState(true);
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [volume, setVolume] = useState(0.3);

  const playerID = currentPlayer.uid;
  const G = game;

  // Take cards from deck

  const takeCardsFromDeck = (G, numToTake, deckType = "action") => {
    const deckName = `${deckType}Deck`;
    const discardPileName = `${deckType}DiscardPile`;

    const refillIfEmpty = () => {
      if (G[deckName].length === 0) {
        G[deckName] = _.shuffle(G[discardPileName]);
        G[discardPileName] = [];
      }
    };

    const drawnCards = [];
    while (drawnCards.length < numToTake) {
      refillIfEmpty();

      if (G[deckName].length === 0) {
        throw Error("Attempting to take cards from empty deck");
      }

      // assumption: there is always at least 1 card in the deck, b/c we refill at the end
      drawnCards.push(G[deckName].pop());

      refillIfEmpty();
    }

    return drawnCards;
  };

  // Moves

  function getCurrentPlayerId() {
    return Object.entries(G.players).find(
      ([_playerID, player]) => player.currentPlayer === true
    )[0];
  }

  const moves = {
    playCard: (id, payload) => {
      if (!id) {
        return;
      }

      const gPlayers = G.players;
      const pId = getCurrentPlayerId();
      const playerName = gamePlayers.find((player) => player.uid === pId)
        .nickname;
      const playerCards = G.players[pId].cards.slice();
      const pirateCards = G.players[pId].pirates.slice();
      const playedCards = G.playedCards;

      // Check if selected card is an action card or a pirate card

      const isActionCard = playerCards.map((card) => card.id).includes(id);
      const isPirateCard = pirateCards.map((card) => card.id).includes(id);
      const cardType = CARD_ID_TO_TYPE[id];

      if (isActionCard) {
        // Action card play move
        const cardToPlay = playerCards.find((card) => card.id === id);

        if (!cardToPlay || !cardType) {
          throw Error(
            `Could not find action card {id: ${id}, type: ${cardType} } in hand`
          );
        }

        // Remove the card from the players hand, add it to the board
        gPlayers[pId].cards = removeItemOnce(playerCards, cardToPlay);
        // const newPlayerCards = removeItemOnce(playerCards, cardToPlay);

        playedCards.push(cardToPlay);

        applyCardEffectReducer({ G, cardType, playerId: pId, payload });

        const actionString = `played action card (${cardToPlay.type}).`;

        G.chat.push({
          player: playerName,
          uid: pId,
          action: actionString,
          automated: true,
        });

        firebase.db.collection("games").doc(game.id).update(game);
      }

      if (isPirateCard) {
        // Pirate card play move
        const cardToPlay = pirateCards.find((card) => card.id === id);

        if (!cardToPlay || !cardType) {
          throw Error(
            `Could not find pirate card {id: ${id}, type: ${cardType} } in hand`
          );
        }

        // Disable the pirate card
        gPlayers[pId].pirates = removeItemOnce(pirateCards, cardToPlay);
        cardToPlay.played = true;
        gPlayers[pId].pirates.push(cardToPlay);

        applyCardEffectReducer({ G, cardType, playerId: pId, payload });

        const actionString = `played pirate (${cardToPlay.type}).`;
        G.chat.push({
          player: playerName,
          uid: pId,
          action: actionString,
          automated: true,
        });

        firebase.db.collection("games").doc(game.id).update(game);
      }
    },
    hirePirate: (id) => {
      if (id == null) {
        return;
      }

      const p = getCurrentPlayerId();
      const playerName = gamePlayers.find((player) => player.uid === p)
        .nickname;
      const cardToHire = G.pirateBoard.filter((card) => card.id === id)[0];

      if (!cardToHire) {
        return;
      }

      const newPirateCards = G.players[p].pirates.slice();
      const replacementPirate = takeCardsFromDeck(G, 1, "pirate");
      newPirateCards.push(cardToHire);

      G.players[p].pirates = newPirateCards;
      G.players[p].doubloons -= cardToHire.cost;
      G.pirateBoard = removeItemOnce(G.pirateBoard, cardToHire);
      G.pirateBoard.push(...replacementPirate);

      const actionString = `hired pirate (${cardToHire.type}).`;
      G.chat.push({
        player: playerName,
        uid: p,
        action: actionString,
        automated: true,
      });

      firebase.db.collection("games").doc(game.id).update(game);
    },
    sellPirate: (id) => {
      const p = getCurrentPlayerId();
      const gPlayers = G.players;
      const pirateDiscardPile = G.pirateDiscardPile;
      const playerName = gamePlayers.find((player) => player.uid === p)
        .nickname;

      if (id == null) {
        return;
      }

      const pirateToSell = G.players[p].pirates.filter(
        (card) => card.id === id
      )[0];

      if (!pirateToSell) {
        return;
      }

      const newPlayerPirates = gPlayers[p].pirates.slice();
      gPlayers[p].pirates = removeItemOnce(newPlayerPirates, pirateToSell);
      pirateToSell.played = false;
      pirateDiscardPile.push(pirateToSell);
      pirateToSell.enabled = true;
      pirateToSell.disabled = false;

      const actionString = `discarded pirate (${pirateToSell.type}).`;
      G.chat.push({
        player: playerName,
        uid: p,
        action: actionString,
        automated: true,
      });

      firebase.db.collection("games").doc(game.id).update({
        players: gPlayers,
        pirateDiscardPile: pirateDiscardPile,
        chat: G.chat,
      });
    },
    endTurn: () => {
      const p = getCurrentPlayerId();
      // move played cards to discard
      G.actionDiscardPile.push(...G.playedCards);

      // reset played cards
      G.playedCards = [];

      // enable your disabled pirates
      G.players[p].pirates.forEach((p) => {
        p.enabled = true;
      });

      Object.values(G.players).forEach((player) => {
        player.pirates.forEach((pirate) => {
          // mark all pirates as not-played
          pirate.played = false;

          // enable pirates that need to be enabled
          if (pirate.forceEnable) {
            delete pirate.forceEnable;
            pirate.enabled = true;
            pirate.played = false;
          }
        });
      });

      // Reset all mutinied pirates in splay
      Object.values(G.pirateBoard).forEach((pirate) => {
        if (pirate.forceEnable) {
          delete pirate.forceEnable;
          pirate.enabled = true;
          pirate.played = false;
        }
      });

      // Players array

      // sort so that the order of players is the same every time
      const playersArr = Object.keys(game.players).sort();

      // Find index of current player

      const currentIndex = playersArr.findIndex(
        (key) => game.players[key].currentPlayer === true
      );

      // Next index

      const nextIndex =
        currentIndex + 1 < playersArr.length ? currentIndex + 1 : 0;

      // Mark next player as current player
      G.players[playersArr[nextIndex]].currentPlayer = true;

      // Mark current player as no longer current player
      G.players[playersArr[currentIndex]].currentPlayer = false;

      // Deal 1 new card to next player

      const cardToTake = takeCardsFromDeck(G, 1, "action");

      G.players[playersArr[nextIndex]].cards = [
        ...G.players[playersArr[nextIndex]].cards,
        ...cardToTake,
      ];

      // Firebase update
      firebase.db.collection("games").doc(game.id).update(game);
    },
  };

  function toggleWinModal() {
    setIsWinOpen(!isWinOpen);
  }

  function toggleSettingsModal() {
    setIsSettingsOpen(!isSettingsOpen);
  }

  const {
    players,
    pirateBoard,
    playedCards,
    actionDeck,
    pirateDeck,
    actionDiscardPile,
    chat,
  } = game;

  let opponents = _.chain(Object.keys(players))
    .without(currentPlayer.uid)
    .chunk(2)
    .value();

  const numPlayers = Object.keys(players).length;

  /*We always want your board to be at the bottom of the screen, but this sometimes breaks clockwise play progression. 
  This checks if we need to reorient a player's opponents. */

  const playersArr = Object.keys(game.players).sort();

  const needsReorientation = () => {
    if (numPlayers === 3 || numPlayers === 4) {
      return true;
    }
    return false;
  };

  //Adjust orientation of a player's opponents so everyone sees game progressing clockwise.
  const reorientOpponents = (
    oldOrientation,
    numPlayers,
    playersArr,
    playerID
  ) => {
    let newOrientation = oldOrientation;

    if (numPlayers === 3) {
      if (playerID === playersArr[1]) {
        newOrientation = {
          0: [playersArr[2], playersArr[0]],
        };
      }

      if (playerID === playersArr[0]) {
        newOrientation = {
          0: [playersArr[1], playersArr[2]],
        };
      }

      if (playerID === playersArr[2]) {
        newOrientation = {
          0: [playersArr[0], playersArr[1]],
        };
      }
    }

    if (numPlayers === 4) {
      if (playerID === playersArr[0]) {
        newOrientation = {
          0: [playersArr[1], playersArr[2]],
          1: [playersArr[3]],
        };
      }

      if (playerID === playersArr[1]) {
        newOrientation = {
          0: [playersArr[2], playersArr[3]],
          1: [playersArr[0]],
        };
      }

      if (playerID === playersArr[2]) {
        newOrientation = {
          0: [playersArr[3], playersArr[0]],
          1: [playersArr[1]],
        };
      }

      if (playerID === playersArr[3]) {
        newOrientation = {
          0: [playersArr[0], playersArr[1]],
          1: [playersArr[2]],
        };
      }
    }

    return newOrientation;
  };

  opponents = needsReorientation()
    ? reorientOpponents(opponents, numPlayers, playersArr, playerID)
    : opponents;

  const iAmActive =
    currentPlayer.uid ===
    Object.keys(game.players).find(
      (key) => game.players[key].currentPlayer === true
    );

  const [uiState, dispatch] = useInterfaceReducer();
  const gameOver = game.status === "complete";

  const onPirateClick = (id) =>
    dispatch({
      type: CARD_SELECT,
      payload: { id, isPirate: true, moves, G, playerID },
    });

  const onActionClick = (id) =>
    dispatch({
      type: CARD_SELECT,
      payload: { id, isAction: true, moves, G, playerID },
    });

  blockPlayIfTooManyPirates(players[getCurrentPlayerId()], uiState, dispatch);

  const canEndTurn =
    uiState.executionQueue[0]?.state !== BOARD_STATES.WAIT_FOR_SELL;

  const showTutorial = () => {
    setIsTourOpen(true);
  };

  const handleVolume = (vol) => {
    setVolume(vol);
  };

  const joinDiscord = () => {
    window.open("https://discord.com/invite/xja9TK6t4j", "_blank");
  };

  const derivedUiState = deriveUiState(
    game,
    uiState,
    currentPlayer.uid,
    iAmActive
  );

  const isMasterPlayerClient =
    me.uid ===
    Object.keys(game.players)
      .filter((p) => p !== AI_PLAYER_ID)
      .sort()[0];
  const isAiTurn = game.players[AI_PLAYER_ID]?.currentPlayer === true;
  const shouldPlayAi = isMasterPlayerClient && isAiTurn;
  if (shouldPlayAi) {
    // eventually be able to support multiple AIs
    try {
      getAndPlayAction(dispatch, game, uiState, moves, AI_PLAYER_ID);
    } catch (e) {
      console.log("Could not find a possible move due to error", e);
      return moves.endTurn();
    }
  }

  const Opponent = (opponentID) => {
    return (
      <PlayerSection
        key={opponentID}
        playerId={opponentID}
        player={players[opponentID]}
        playerName={gamePlayers.find((el) => el.uid === opponentID).nickname}
        isActive={currentPlayer === opponentID}
        uiState={derivedUiState.players[opponentID]}
        onBoardClick={() =>
          dispatch({
            type: PLAYER_SELECT,
            payload: { playerID: opponentID, moves },
          })
        }
        onActionClick={onActionClick}
        onPirateClick={onPirateClick}
      />
    );
  };

  return (
    <div className="board">
      {/* <HeaderNav showTutorial={showTutorial} tour="tour-sixth-step" /> */}
      <button className="tutorial tour-seventh-step" onClick={showTutorial}>
        <h3>Tutorial</h3>
      </button>
      <button className="discord-button" onClick={joinDiscord}>
        <h3>Discord</h3>
      </button>
      <button className="settings" onClick={toggleSettingsModal}>
        <h3>Settings</h3>
      </button>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        accentColor="goldenrod"
      />
      <Modal
        isOpen={isSettingsOpen}
        onRequestClose={() => setIsSettingsOpen(false)}
        contentLabel="Settings Screen"
        className="settingsmodal"
        overlayClassName="settingsoverlay"
        closeTimeoutMS={500}
      >
        <div>
          <h1>Volume</h1>
          Adjust your volume for game effect sounds here. We'll be adding
          additional controls to improve performance soon.
          <br></br>
          <br></br>
          <Volume volumeLevel={volume} volumeCallback={handleVolume} />
        </div>
      </Modal>
      <Modal
        isOpen={gameOver && isWinOpen}
        onRequestClose={toggleWinModal}
        contentLabel="Win Screen"
        className="winmodal"
        overlayClassName="winoverlay"
        closeTimeoutMS={500}
      >
        <div className="winmodal__outcome">
          {/* Game Over! */}
          {iAmActive ? "Victory!" : "Defeated :("}
        </div>
        {gameOver &&
          Object.keys(game.players).map((player) => (
            <div className="winmodal__scoreboard">
              {gamePlayers.find((el) => el.uid === player).nickname}:{" "}
              {game.players[player].doubloons}
              <div className="winmodal__doubloons">
                <img id="right" alt="Doubloon" src={logo} />
              </div>
            </div>
          ))}
        <Link to="/">
          <span className="play-again-button">Play again</span>
        </Link>
        <a
          className="tabletoplink"
          href="https://gumroad.com/l/doubloons"
          target="_blank"
          rel="noopener noreferrer"
        >
          Buy tabletop version
        </a>
      </Modal>
      {/* Opponent section */}
      <div className="board-section board-section__top">
        {opponents[0]?.map(Opponent)}
      </div>
      {/* Middle game board section*/}
      <div className="board-section__middle">
        <div className="board-table ">
          {/* Pirate Deck splay */}
          <CardContainer
            className="tour-fourth-step"
            cards={pirateBoard}
            selectedCardIds={iAmActive ? [uiState.selectedCardId] : []}
            faceUp
            length={pirateDeck.length}
            onClick={onPirateClick}
            onHirePirate={() => {
              dispatch({
                type: PIRATE_HIRE,
                payload: { playerID, moves, G },
              });
            }}
            displayType={CARDS_DISPLAY_SPLAY}
            cardType={CARD_TYPE_PIRATE}
            uiState={derivedUiState.pirateBoard}
            volume={volume}
          />
          {/* Action Deck, played action cards, and action discard pile*/}
          <CardContainer
            className="tour-third-step"
            faceUp
            length={actionDeck.length}
            onClick={onActionClick}
            cards={playedCards}
            selectedCardIds={iAmActive ? [uiState.selectedCardId] : []}
            displayType={CARDS_DISPLAY_SPLAY}
            uiState={derivedUiState.playedCards}
            numSlots={3}
            discardPile={actionDiscardPile}
            volume={volume}
          />
        </div>
        <div className="turn-indicator tour-fifth-step">
          {iAmActive && (
            <div className="board-prompt tour-fifth-step">
              {getExecutionMessage(uiState)}
            </div>
          )}
          <PlayerMoves
            active={{
              playCard: true,
              hirePirate: true,
              sellPirate: true,
              endTurn: canEndTurn,
            }}
            onPlayCard={() => {
              dispatch({ type: PLAY, payload: { moves, G } });
            }}
            onHirePirate={() => {
              dispatch({
                type: PIRATE_HIRE,
                payload: { playerID, moves, G },
              });
            }}
            onSellPirate={() => {
              moves.sellPirate(uiState.selectedCardId);
            }}
            onEndTurn={() => moves.endTurn()}
            clearHandler={() => {}}
            activePlayer={iAmActive}
            currentPlayerName={
              gamePlayers.find(
                (el) =>
                  el.uid ===
                  Object.keys(game.players).find(
                    (key) => game.players[key].currentPlayer === true
                  )
              ).nickname
            }
            gameOver={gameOver}
            volume={volume}
            chat={chat}
            myUid={currentPlayer.uid}
          />
        </div>
      </div>
      <div className="board-section board-section__bottom fd-col tour-second-step">
        {/* Actions the active player can take */}
        <PlayerSection
          playerId={currentPlayer.uid}
          playerName={
            gamePlayers.find((el) => el.uid === currentPlayer.uid).nickname
          }
          player={players[currentPlayer.uid]}
          isMe
          isActive={iAmActive}
          onBoardClick={() =>
            dispatch({
              type: PLAYER_SELECT,
              payload: { playerID, G, moves },
            })
          }
          onPlayCard={() => {
            dispatch({ type: PLAY, payload: { moves, G } });
          }}
          onActionClick={onActionClick}
          onPirateClick={onPirateClick}
          uiState={derivedUiState.players[currentPlayer.uid]}
          handSelected={[uiState.selectedCardId]}
          pirateSelected={[uiState.selectedCardId]}
          explanations={derivedUiState.explanations}
          volume={volume}
        />
        {opponents[1]?.map(Opponent)}
      </div>
      <Sidebar chat={chat} me={currentPlayer.uid} />
    </div>
  );
}
