import React from "react";
import classNames from "classnames";

import { PLAY_STATES } from "../../constants";
import "./Card.scss";

import useSound from "use-sound";
import click from "../../assets/sounds/click.wav";
import hire from "../../assets/sounds/level_up.wav";
import play from "../../assets/sounds/whoosh.wav";

export const CARD_TYPE_ACTION = "action";
export const CARD_TYPE_PIRATE = "pirate";

export default function Card({
  className,
  cardType = CARD_TYPE_ACTION,
  card,
  faceUp,
  selected,
  shouldStack,
  onPlayCard,
  onHirePirate,
  onClick,
  playState,
  explanation,
  volume,
}) {
  const imageClassName = faceUp ? card?.type.toLowerCase() : cardType;
  const [clickSound] = useSound(click, { volume: volume });
  const [hireSound] = useSound(hire, { volume: volume });
  const [playSound] = useSound(play, { volume: volume });

  return (
    <>
      <div
        data-testid="card"
        data-type={faceUp && card?.type}
        onClick={
          onClick &&
          ((e) => {
            if (playState !== PLAY_STATES.NOT_PLAYABLE) {
              onClick(card.id);
              clickSound();
            }
            e.stopPropagation();
          })
        }
        className={classNames("card", className, {
          selected,
          [playState]: playState,
          "card-stack": shouldStack,
        })}
      >
        {onHirePirate && playState === PLAY_STATES.PLAYABLE && selected && (
          <button
            className="card-button"
            onClick={(e) => {
              onHirePirate();
              hireSound();
              e.stopPropagation();
            }}
          >
            Hire
          </button>
        )}
        <div
          className={classNames(
            "card-inside ",
            imageClassName,
            !card?.enabled && card?.cost >= 0 ? "card-disabled" : "",
            card?.played && card?.enabled && card?.cost >= 0
              ? "card-played"
              : ""
          )}
        >
          {onPlayCard && playState === PLAY_STATES.PLAYABLE && selected && (
            <button
              className="card-button"
              onClick={(e) => {
                onPlayCard();
                playSound();
                e.stopPropagation();
              }}
            >
              Play
            </button>
          )}
          {explanation && <div className="explanation">{explanation}</div>}
          {card?.cost >= 0 && <div className="card-cost">{card?.cost}</div>}
        </div>
        <div className={classNames("card-zoomed", imageClassName)} />
      </div>
    </>
  );
}
