import React, { useContext, useState } from "react";
// import _ from "lodash";
import "./Join.css";
import Header from "../components/header";
import { AuthContext } from "../firebase/authentication";
import Login from "./Login";

export default function Join({ history }) {
  const { currentUser } = useContext(AuthContext);
  const [code, setCode] = useState("");

  const handleChange = (code) => {
    setCode(code);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    history.push("/game/" + code);
  };

  if (!currentUser) {
    return <Login />;
  }

  return (
    <>
      <Header />
      <div>
        <div className="Join-content">
          <h1 className="Join-title">Enter your game code</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              className="Join-input"
              placeholder={"0000"}
              maxLength="10"
              onChange={(e) => handleChange(e.target.value)}
            />
            <br />
            <br />
            <button type="submit" className="Join-button">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
