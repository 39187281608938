import React from "react";
import "./styles/homePage.css";
import wordmark from "../assets/wordmark.png";
import Header from "./header";
import MobileCover from "./mobileCover";
import { useWindowSize } from "../utils/useWindowSize";

const HomePage = ({ history }) => {
  const windowSize = useWindowSize();

  const windowTooSmall = ["sm", "md"].includes(windowSize);

  return (
    <>
      <div className="Home">
        <Header />
        <header className="Home-header">
          <img src={wordmark} className="Home-logo" alt="logo" />
        </header>
        {windowTooSmall ? (
          <MobileCover />
        ) : (
          <>
            <button
              className="Home-button"
              onClick={() => history.push("/game/create")}
            >
              New Game
            </button>
            <br />
            <br />
            <button
              className="Home-button"
              onClick={() => {
                history.push("/find");
              }}
            >
              Find Game
            </button>
            <br />
            <br />
            <button
              className="Home-button"
              onClick={() => {
                history.push("/join");
              }}
            >
              Enter Code
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default HomePage;
