import _ from "lodash";
import { useImmerReducer } from "use-immer";
import {
  ACTIONS,
  BOARD_STATES,
  CARD_ID_TO_TYPE,
  MAX_PIRATES,
} from "../../../constants";

import CARD_PROPERTIES from "./cardProperties";

const playCardIfPossible = (state, moves) => {
  const cardType = CARD_ID_TO_TYPE[state.selectedCardId];
  if (state.executionQueue.length === 0) {
    moves.playCard(
      state.selectedCardId,
      CARD_PROPERTIES[cardType].composePayload(state.selectedIds)
    );
    resetSelection(state);
  }
};

const clearSellIfPossible = (G, playerID, state) => {
  if (
    state.executionQueue[0]?.state === BOARD_STATES.WAIT_FOR_SELL &&
    G.players[playerID].pirates.length < MAX_PIRATES
  ) {
    state.executionQueue.shift();
  }
};

export const getLastPlaydCard = (G) => {
  const filter = (c) => c.type !== ACTIONS.fireinthehole;

  const playedCards = G.playedCards.filter(filter);
  const discardPile = G.actionDiscardPile.filter(filter);

  const lastPlayedCard = _.last(playedCards) || _.last(discardPile);

  return lastPlayedCard;
};

export const handleHire = (state, action) => {
  const { moves, G, playerID } = action.payload;

  if (G.players[playerID].pirates.length < MAX_PIRATES) {
    moves.hirePirate(state.selectedCardId);
    resetSelection(state);
  } else {
    state.executionQueue = [{ state: BOARD_STATES.WAIT_FOR_SELL }];
  }
};

export const handlePlay = (state, action) => {
  const { G, moves } = action.payload;
  const execution = state.executionQueue[0];

  if (!execution) {
    return;
  }

  if (execution.state !== BOARD_STATES.WAIT_FOR_PLAY) {
    return;
  }

  const cardType = CARD_ID_TO_TYPE[state.selectedCardId];
  const lastPlayedCard = getLastPlaydCard(G);

  const props = CARD_PROPERTIES[cardType].chainLastPlayed
    ? CARD_PROPERTIES[lastPlayedCard.type]
    : CARD_PROPERTIES[cardType];

  if (CARD_PROPERTIES[cardType].chainLastPlayed) {
    state.selectedIds = [lastPlayedCard.id];
    state.chainedSelectedCardId = lastPlayedCard.id;
  }

  state.executionQueue.push(...props.selectionQueue);
  state.executionQueue.shift();

  playCardIfPossible(state, moves);
};

export const handleCardSelect = (state, action) => {
  const {
    id,
    moves,
    isPirate = false,
    isAction = false,
    G,
    playerID,
  } = action.payload;

  const execution = state.executionQueue.shift();

  if (execution?.state === BOARD_STATES.WAIT_FOR_SELL) {
    // if you use bart, you'll buy what you selected second
    // if you are replacing from the board, you'll buy what you selected first
    const idToBuy = state.selectedIds[0] || state.selectedCardId;
    moves.sellPirate(id);
    moves.hirePirate(idToBuy);
    resetSelection(state);
    clearSellIfPossible(G, playerID, state);
    return;
  }

  if (
    (execution?.state === BOARD_STATES.WAIT_FOR_ACTION_SELECT && isAction) ||
    (execution?.state === BOARD_STATES.WAIT_FOR_PIRATE_SELECT && isPirate) ||
    (execution?.state === BOARD_STATES.WAIT_FOR_BOARD_SELECT && isAction)
  ) {
    state.selectedIds.push(id);

    const cardType = CARD_ID_TO_TYPE[id];
    // for commandeer / hendrick, we want to chain into the next set of actions
    if (execution?.chainSelection) {
      state.executionQueue.push(...CARD_PROPERTIES[cardType].selectionQueue);
      state.chainedSelectedCardId = id;
    }
  } else {
    // if already selected, deselect
    const prevSelectedId = state.selectedCardId;
    resetSelection(state);
    if (id === prevSelectedId) {
      return;
    }

    state.selectedCardId = id;

    if (G.pirateBoard.map((p) => p.id).includes(id)) {
      state.executionQueue = [{ state: BOARD_STATES.WAIT_FOR_HIRE }];
    } else {
      state.executionQueue = [{ state: BOARD_STATES.WAIT_FOR_PLAY }];
    }

    state.selectedIds = [];
  }

  clearSellIfPossible(G, playerID, state);
  playCardIfPossible(state, moves);
};

const handlePlayerSelect = (state, action) => {
  const { moves, playerID } = action.payload;

  const execution = state.executionQueue[0];
  if (!execution) {
    resetSelection(state);
    return;
  }

  if (execution.state !== BOARD_STATES.WAIT_FOR_PLAYER_SELECT) {
    console.log("Cannot select player right now");
    return;
  }

  state.executionQueue.shift();
  state.selectedIds.push(playerID);
  playCardIfPossible(state, moves);
};

const handleBlockPlayUntilSell = (state, action) => {
  state.executionQueue = [{ state: BOARD_STATES.WAIT_FOR_SELL }];
};

const handleUnblockPlayAfterSell = (state, _action) => {
  state.executionQueue = [];
};

/* Helpers */
const resetSelection = (state) => {
  state.selectedCardId = null;
  state.chainedSelectedCardId = null;
  state.selectedIds = [];
  state.executionQueue = [];
};

export const PLAY = "PLAY";
export const CARD_SELECT = "ACTION_CARD_SELECT";
export const PIRATE_HIRE = "PIRATE_HIRE";
export const BLOCK_PLAY_UNTIL_SELL = "BLOCK_PLAY_UNTIL_SELL";
export const UNBLOCK_PLAY_AFTER_SELL = "UNBLOCK_PLAY_AFTER_SELL";
export const PLAYER_SELECT = "PLAYER_SELECT";
export const TRIGGER_FOLLOWUP = "TRIGGER_FOLLOWUP";

const handlers = {
  [PLAY]: handlePlay,
  [CARD_SELECT]: handleCardSelect,
  [PIRATE_HIRE]: handleHire,
  [BLOCK_PLAY_UNTIL_SELL]: handleBlockPlayUntilSell,
  [UNBLOCK_PLAY_AFTER_SELL]: handleUnblockPlayAfterSell,
  [PLAYER_SELECT]: handlePlayerSelect,
};

export const createInitialInterfaceState = () => {
  return {
    selectedCardId: null,
    chainedSelectedCardId: null,

    executionQueue: [],
    selectedIds: [],
  };
};

export const useInterfaceReducer = () =>
  useImmerReducer(reducer, createInitialInterfaceState());

const reducer = (state, action) => {
  // console.debug(`[${action.type}]`, JSON.parse(JSON.stringify(state)), action);
  const handler = handlers[action.type];
  return handler(state, action);
};
