import React from "react";
// import _ from "lodash";
import logo from "../../assets/logo.png";
import "./ScoreBoard.scss";

// function multiplyCards(n) {
//   return _.chunk(_.range(n), 4).map((row, j) => (
//     <div className="scoreboard__coins-row" key={j}>
//       {row.map((i) => (
//         <img key={i} style={{}} alt="Doubloon" src={logo} />
//       ))}
//     </div>
//   ));
// }

export default function ScoreBoard({ score, playerName }) {
  return (
    <div className="scoreboard">
      <div className="scoreboard__name">{playerName}</div>
      <div className="scoreboard__doubloons">
        <div id="left">{score}</div>
        <img id="right" alt="Doubloon" src={logo} />
      </div>
    </div>
  );
}
