import React from "react";
import { Switch, Route, useHistory } from "react-router";

// Routing
import PublicRoute from "./components/Routing/PublicRoute";

// Pages
import GameConfig from "./pages/GameConfig";
import Login from "./pages/Login";
import Join from "./pages/Join";
import GamePage from "./pages/GamePage";
import GamesList from "./pages/GamesList";
import HomePage from "./components/homePage";
import HelpPage from "./components/helpPage";

// Firebase
import { AuthProvider } from "./firebase/authentication";

function App() {
  const history = useHistory();

  return (
    <AuthProvider>
      <Switch>
        <PublicRoute
          path="/home"
          exact
          render={(props) => <HomePage {...props} history={history} />}
        />
        <PublicRoute
          path="/help"
          exact
          render={(props) => <HelpPage {...props} history={history} />}
        />
        <PublicRoute path="/find" exact component={GamesList} />
        <PublicRoute path="/join" exact component={Join} />
        <PublicRoute path="/login" exact component={Login} />
        <PublicRoute path="/game/create" exact component={GameConfig} />
        <PublicRoute exact path="/game/:gameId" component={GamePage} />
        <Route
          path="*"
          render={(props) => <HomePage {...props} history={history} />}
        />
      </Switch>
    </AuthProvider>
  );
}

export default App;
