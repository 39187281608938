import deriveUiState from "./store/deriveUiState";
import { BOARD_STATES, PLAY_STATES } from "../../constants";
import _ from "lodash";

import { PLAYER_SELECT, CARD_SELECT, PLAY, PIRATE_HIRE } from "./store";
import { unblockPlayIfPossible } from "./blockPlayIfTooManyPirates";

// const AVAILABLE_ACTIONS = [PLAYER_SELECT, CARD_SELECT, PLAY, PIRATE_HIRE];

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// function getRandomPlayableIndex(array, desiredState) {
//   const playableIdxs = [];
//   array.forEach((val, i) => {
//     if (val === desiredState) {
//       playableIdxs.push(i);
//     }
//   });

//   if (playableIdxs.length === 0) {
//     return -1;
//   }

//   return _.shuffle(playableIdxs)[0];
// }

function constructPlayableActions(G, ui, state, aiId) {
  const actions = {
    [BOARD_STATES.WAIT_FOR_SELL]: [],
    [PLAY_STATES.CHOOSABLE]: [],
    [PLAY_STATES.PLAYABLE]: [],
    [PLAY_STATES.CLICKABLE]: [],
    [PLAY_STATES.NOT_PLAYABLE]: [],
  };

  if (ui.executionQueue[0]?.state === BOARD_STATES.WAIT_FOR_SELL) {
    actions[BOARD_STATES.WAIT_FOR_SELL].push({
      type: "SELL_PIRATE",
      payload: _.sample(G.players[aiId].pirates).id,
    });
  }

  state.pirateBoard.forEach((p, i) => {
    const id = G.pirateBoard[i].id;
    if (state.pirateBoard[i] !== PLAY_STATES.PLAYABLE) {
      return;
    }
    actions[p].push([
      {
        type: CARD_SELECT, // hire pirate here
        payload: { isPirate: true, id, playerID: aiId },
      },
      {
        type: PIRATE_HIRE,
        payload: { playerID: aiId },
      },
    ]);
  });

  Object.entries(state.players).forEach(([pid, player]) => {
    player.actions.forEach((a, i) => {
      const id = G.players[pid].cards[i].id;
      actions[a].push({
        type: CARD_SELECT,
        payload: { isAction: true, id, playerID: aiId },
      });
    });
    player.pirates.forEach((a, i) => {
      const id = G.players[pid].pirates[i].id;
      actions[a].push({
        type: CARD_SELECT,
        payload: { isPirate: true, id, playerID: aiId },
      });
    });
    actions[player.board].push({
      type: PLAYER_SELECT,
      payload: { playerID: pid },
    });
  });

  return actions;
}

function getPlayableActions(G, ui, state, aiId) {
  const availableMoves = constructPlayableActions(G, ui, state, aiId);

  if (availableMoves[BOARD_STATES.WAIT_FOR_SELL].length) {
    return availableMoves[BOARD_STATES.WAIT_FOR_SELL];
  }

  const bestActions =
    _.shuffle(availableMoves[PLAY_STATES.CHOOSABLE])[0] ||
    _.shuffle(availableMoves[PLAY_STATES.PLAYABLE])[0] ||
    _.shuffle(availableMoves[PLAY_STATES.CLICKABLE])[0];

  if (!bestActions) {
    return [];
  }
  if (bestActions.length) {
    return bestActions;
  }
  return [bestActions];
}

const CHANCE_OF_OVERHIRING_PIRATE = 0.2;
const CHANCE_OF_ENDING_PER_CARD = 0.2;

async function _getAndPlayAction(dispatch, G, ui, moves, aiId) {
  if (G.status === "complete") {
    return;
  }

  const state = deriveUiState(G, ui, aiId);
  const playableActions = getPlayableActions(G, ui, state, aiId);

  const chanceOfFinishing = CHANCE_OF_ENDING_PER_CARD * G.playedCards.length;
  if (Math.random() < chanceOfFinishing) {
    console.log("Finishing because played some already");
    return moves.endTurn();
  }

  await sleep(1750);
  console.log(playableActions.forEach((a) => console.log(a)));

  if (playableActions[0]?.type === "SELL_PIRATE") {
    moves.sellPirate(playableActions[0].payload);
    unblockPlayIfPossible(G.players[aiId], ui, dispatch);
    return;
  }

  if (
    playableActions[0]?.type === "ACTION_CARD_SELECT" &&
    playableActions[0]?.payload?.id === "P8_HENDRICKQUINTOR"
  ) {
    return moves.endTurn();
  }

  if (
    playableActions[0]?.type === PIRATE_HIRE &&
    G.players[aiId].pirates.length === 2
  ) {
    if (Math.random() > CHANCE_OF_OVERHIRING_PIRATE) {
      console.log("Ending to avoid buying pirate when unnecessary");
      return moves.endTurn();
    }
  }

  if (playableActions.length === 0) {
    return moves.endTurn();
  }

  playableActions.forEach((a) => {
    Object.assign(a.payload, { moves, G });
  });

  playableActions.forEach(dispatch);
  dispatch({ type: PLAY, payload: { moves, G } });
}

export const getAndPlayAction = _.debounce(_getAndPlayAction, 1000);
