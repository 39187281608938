import React, { Component } from "react";
import "./styles/templatePage.css";
import { Link } from "react-router-dom";
class TemplatePage extends Component {
  render() {
    return (
      <div className="full_height app-section">
        <Link to="/" style={{ textDecoration: "none" }}>
          <div className="title">
            <div className="doubloons-logo" />
            <br />
            <div className="title-text">Doubloons</div>
            <br />
            <div className="subtitle-text">A game of pillage and plunder</div>
            <br />
          </div>
        </Link>
        <br></br>
        <br></br>
        {this.props.content}
      </div>
    );
  }
}

export default TemplatePage;
