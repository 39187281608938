const firebaseConfig = {
  apiKey: "AIzaSyCWTfFnblyPBSTRL8jhmXe9RpHBSx_igRw",
  authDomain: "doubloons-7e2e7.firebaseapp.com",
  databaseURL: "https://doubloons-7e2e7-default-rtdb.firebaseio.com",
  projectId: "doubloons-7e2e7",
  storageBucket: "doubloons-7e2e7.appspot.com",
  messagingSenderId: "572328507547",
  appId: "1:572328507547:web:14301a0278cb79e053ae3e",
};

export default firebaseConfig;
