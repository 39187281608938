import React, { Component } from "react";
import "./styles/sidebar.css";
class Sidebar extends Component {
  state = {
    open: false,
  };
  toggleState = () => {
    const open = this.state.open;
    this.setState({ open: !open });
  };

  componentDidMount() {
    if (this.state.open) {
      this.scrollToBottom();
    }
  }

  componentDidUpdate() {
    if (this.state.open) {
      this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    this.chatBottom.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    const sidebarContent = (
      <>
        <div className="chatbox">
          <h2 className="chat-item header">
            <span role="img" aria-label="scroll">
              {" "}
              📜{" "}
            </span>
            Move Log{" "}
          </h2>
          {this.props.chat.map((chatItem, i) => {
            const isMe = String(chatItem.uid) === String(this.props.me);
            if (isMe) {
              return (
                <div key={i} className="chat-item me">
                  <b>You</b>
                  <br />
                  {chatItem.action}
                </div>
              );
            } else {
              return (
                <div key={i} className="chat-item not-me">
                  <b>{chatItem.player}</b>
                  <br />
                  {chatItem.action}
                </div>
              );
            }
          })}
          <div
            ref={(chatBottom) => {
              this.chatBottom = chatBottom;
            }}
          />
        </div>
      </>
    );
    return (
      <div
        className={
          "sidebar " +
          (this.state.open ? "open" : "closed") +
          " tour-sixth-step"
        }
      >
        <div className={"sidebar-tab"} onClick={this.toggleState}>
          <div className="icon-container">
            <h3>History</h3>
          </div>
        </div>
        <div className="sidebar-content">
          {this.state.open ? sidebarContent : null}
        </div>
      </div>
    );
  }
}

export default Sidebar;
