import _ from "lodash";

export const RESOURCES = Object.freeze({
  gold: "GOLD",
});

export const PIRATES = Object.freeze({
  annebonny: "ANNEBONNY",
  barbarossa: "BARBAROSSA",
  bartholomewroberts: "BARTHOLOMEWROBERTS",
  blackbeard: "BLACKBEARD",
  blackcaesar: "BLACKCAESAR",
  charlottebadger: "CHARLOTTEBADGER",
  cheungpotsai: "CHEUNGPOTSAI",
  chingshih: "CHINGSHIH",
  francisfernando: "FRANCISFERNANDO",
  graceomalley: "GRACEOMALLEY",
  hendrickquintor: "HENDRICKQUINTOR",
  henrymorgan: "HENRYMORGAN",
  kanhojiangre: "KANHOJIANGRE",
  maryread: "MARYREAD",
  rachelwall: "RACHELWALL",
  samuelbellamy: "SAMUELBELLAMY",
  sayyidaalhurra: "SAYYIDAALHURRA",
  williamkid: "WILLIAMKID",
});

export const ACTIONS = Object.freeze({
  scallywag: "SCALLYWAG",
  yohoho: "YOHOHO",
  barter: "BARTER",
  coffer: "COFFER",
  plunder: "PLUNDER",
  pillage: "PILLAGE",
  xmarksthespot: "XMARKSTHESPOT",
  commandeer: "COMMANDEER",
  fireinthehole: "FIREINTHEHOLE",
  marooned: "MAROONED",
  mehearties: "MEHEARTIES",
  mutiny: "MUTINY",
  spyglass: "SPYGLASS",
  walktheplank: "WALKTHEPLANK",
});

// Defining some Game constants here
let ACTION_DECK_CONTENTS = {};
ACTION_DECK_CONTENTS[ACTIONS.commandeer] = 6;
ACTION_DECK_CONTENTS[ACTIONS.fireinthehole] = 4;
ACTION_DECK_CONTENTS[ACTIONS.marooned] = 3;
ACTION_DECK_CONTENTS[ACTIONS.mehearties] = 0;
ACTION_DECK_CONTENTS[ACTIONS.mutiny] = 4;
ACTION_DECK_CONTENTS[ACTIONS.spyglass] = 0;
ACTION_DECK_CONTENTS[ACTIONS.walktheplank] = 2;
ACTION_DECK_CONTENTS[ACTIONS.plunder] = 4;
ACTION_DECK_CONTENTS[ACTIONS.barter] = 3;
ACTION_DECK_CONTENTS[ACTIONS.coffer] = 2;
ACTION_DECK_CONTENTS[ACTIONS.pillage] = 4;
ACTION_DECK_CONTENTS[ACTIONS.scallywag] = 3;
ACTION_DECK_CONTENTS[ACTIONS.xmarksthespot] = 12;
ACTION_DECK_CONTENTS[ACTIONS.yohoho] = 1;
ACTION_DECK_CONTENTS = Object.freeze(ACTION_DECK_CONTENTS);

let PIRATE_DECK_CONTENTS = {};
PIRATE_DECK_CONTENTS[PIRATES.annebonny] = {
  count: 1,
  cost: 3,
  enabled: true,
  played: false,
};
PIRATE_DECK_CONTENTS[PIRATES.barbarossa] = {
  count: 1,
  cost: 3,
  enabled: true,
  played: false,
};
PIRATE_DECK_CONTENTS[PIRATES.bartholomewroberts] = {
  count: 1,
  cost: 2,
  enabled: true,
  played: false,
};
PIRATE_DECK_CONTENTS[PIRATES.blackbeard] = {
  count: 1,
  cost: 1,
  enabled: true,
  played: false,
};
PIRATE_DECK_CONTENTS[PIRATES.blackcaesar] = {
  count: 1,
  cost: 2,
  enabled: true,
  played: false,
};
PIRATE_DECK_CONTENTS[PIRATES.charlottebadger] = {
  count: 0,
  cost: 1,
  enabled: true,
  played: false,
};
PIRATE_DECK_CONTENTS[PIRATES.cheungpotsai] = {
  count: 1,
  cost: 1,
  enabled: true,
  played: false,
};
PIRATE_DECK_CONTENTS[PIRATES.chingshih] = {
  count: 1,
  cost: 3,
  enabled: true,
  played: false,
};
PIRATE_DECK_CONTENTS[PIRATES.francisfernando] = {
  count: 0,
  cost: 2,
  enabled: true,
  played: false,
};
PIRATE_DECK_CONTENTS[PIRATES.graceomalley] = {
  count: 1,
  cost: 1,
  enabled: true,
  played: false,
};
PIRATE_DECK_CONTENTS[PIRATES.hendrickquintor] = {
  count: 1,
  cost: 3,
  enabled: true,
  played: false,
};
PIRATE_DECK_CONTENTS[PIRATES.henrymorgan] = {
  count: 1,
  cost: 1,
  enabled: true,
  played: false,
};
PIRATE_DECK_CONTENTS[PIRATES.kanhojiangre] = {
  count: 1,
  cost: 2,
  enabled: true,
  played: false,
};
PIRATE_DECK_CONTENTS[PIRATES.maryread] = {
  count: 1,
  cost: 2,
  enabled: true,
  played: false,
};
PIRATE_DECK_CONTENTS[PIRATES.rachelwall] = {
  count: 1,
  cost: 3,
  enabled: true,
  played: false,
};
PIRATE_DECK_CONTENTS[PIRATES.samuelbellamy] = {
  count: 1,
  cost: 1,
  enabled: true,
  played: false,
};
PIRATE_DECK_CONTENTS[PIRATES.sayyidaalhurra] = {
  count: 1,
  cost: 2,
  enabled: true,
  played: false,
};
PIRATE_DECK_CONTENTS[PIRATES.williamkid] = {
  count: 1,
  cost: 3,
  enabled: true,
  played: false,
};
PIRATE_DECK_CONTENTS = Object.freeze(PIRATE_DECK_CONTENTS);

const generateDeck = () => {
  const deck = [];
  let id = 0;
  Object.keys(ACTION_DECK_CONTENTS).forEach((type) => {
    const count = ACTION_DECK_CONTENTS[type];
    for (let i = 0; i < count; i++) {
      deck.push({
        // id: "A" + id++,
        id: `A${id++}_${type}`,
        class: "action",
        type,
      });
    }
  });
  return deck;
};

const generatePirateDeck = () => {
  const deck = [];
  let id = 0;
  Object.entries(PIRATE_DECK_CONTENTS).forEach(([type, pirate]) => {
    const count = PIRATE_DECK_CONTENTS[type].count;
    for (let i = 0; i < count; i++) {
      deck.push({
        id: `P${id++}_${type}`,
        class: "pirate",
        type,
        enabled: true,
        played: false,
        cost: pirate.cost,
      });
    }
  });
  return deck;
};

export const ACTION_CARD_TYPES = new Set(Object.values(ACTIONS));
export const PIRATE_CARD_TYPES = new Set(Object.values(PIRATES));

export const ACTION_DECK = generateDeck();
export const PIRATE_DECK = generatePirateDeck();

export const MAX_PIRATES = 2;
export const INITIAL_GOLD = 25;
export const NUMBER_OF_ACTION_CARDS = _.sum(
  Object.values(ACTION_DECK_CONTENTS)
);

export const NUMBER_OF_PIRATE_CARDS = _.sum(
  Object.values(PIRATE_DECK_CONTENTS).map((i) => i.count)
);

export const CARD_ID_TO_TYPE = Object.freeze(
  _.chain([...ACTION_DECK, ...PIRATE_DECK])
    .keyBy("id")
    .mapValues((c) => c.type)
    .value()
);

export const PIRATE_ID_TO_COST = Object.freeze(
  _.chain(PIRATE_DECK)
    .keyBy("id")
    .mapValues((c) => c.cost)
    .value()
);

export const BOARD_STATES = Object.freeze({
  READY: "READY",
  WAIT_FOR_HIRE: "WAIT_FOR_HIRE",
  WAIT_FOR_PLAY: "WAIT_FOR_PLAY",
  WAIT_FOR_SELL: "WAIT_FOR_SELL",
  WAIT_FOR_ACTION_SELECT: "WAIT_FOR_ACTION_SELECT",
  WAIT_FOR_PLAYER_SELECT: "WAIT_FOR_PLAYER_SELECT",
  WAIT_FOR_PIRATE_SELECT: "WAIT_FOR_PIRATE_SELECT",
  WAIT_FOR_BOARD_SELECT: "WAIT_FOR_BOARD_SELECT",
});

export const PLAY_STATES = Object.freeze({
  NOT_PLAYABLE: "not-playable", // you can't click on this, nothing will happen
  CLICKABLE: "clickable", // you could click it and something would happen (usually reset)
  PLAYABLE: "playable", // you haven't played a card yet and you can play this
  CHOOSABLE: "choosable", // the game wants you to choose one of these
});

export const GAME_MAX_PLAYERS = 4;

export const AI_PLAYER_ID = "AI_PLAYER";
