import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { GAME_MAX_PLAYERS } from "../constants";
import "./GameConfig.css";
import Header from "../components/header";
import firebase from "../firebase";
import { AuthContext } from "../firebase/authentication";
import Login from "./Login";
import { ACTION_DECK, PIRATE_DECK, AI_PLAYER_ID } from "../constants";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const DEFAULT_PLAYER = {
  pirates: [],
  cards: [],
  doubloons: 0,
  currentPlayer: true,
};

export default function GameConfig({ history }) {
  const { currentUser } = useContext(AuthContext);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [currentPlayerLoading, setCurrentPlayerLoading] = useState(true);
  const [error, setError] = useState(false);

  const addAIPlayer = async (game, gameCode) => {
    const gamesRef = firebase.db.collection("games").doc(game.id);
    const gamePromise = gamesRef.update({
      [`players.${AI_PLAYER_ID}`]: Object.assign({}, DEFAULT_PLAYER, {
        currentPlayer: false,
      }),
    });

    const playerPromise = firebase.db
      .collection("players")
      .doc(AI_PLAYER_ID)
      .update({
        games: firebase.firestore.FieldValue.arrayUnion(gameCode),
      });

    return Promise.all([gamePromise, playerPromise]);
  };

  const onSizeSelect = async (numPlayers, { aiPlayer = false }) => {
    const defaultPlayer = Object.assign({}, DEFAULT_PLAYER);
    // new firebase game
    const newGameData = {
      createdAt: new Date(),
      code: makeid(5),
      numPlayers: numPlayers,
      players: { [currentUser.uid]: defaultPlayer },
      type: "public",
      status: "joinable",
      pirateBoard: [],
      playedCards: [],
      actionDiscardPile: [],
      pirateDiscardPile: [],
      actionDeck: _.shuffle(ACTION_DECK),
      pirateDeck: _.shuffle(PIRATE_DECK),
      chat: [],
    };

    // adding game to firebase
    const game = await firebase.db.collection("games").add(newGameData);

    // add gameId to "player" collection doc
    await firebase.db
      .collection("players")
      .doc(currentPlayer.id)
      .update({
        games: [...currentPlayer.games, newGameData.code],
      });

    if (aiPlayer) {
      await addAIPlayer(game, newGameData.code);
    }

    // redirect to game page
    history.push("/game/" + newGameData.code);
  };

  const fetchCurrentPlayer = () => {
    if (!currentUser) {
      return;
    }

    const unsubscribe = firebase.db
      .collection("players")
      .where("uid", "==", currentUser.uid)
      .onSnapshot(
        (snapshot) => {
          const players = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCurrentPlayer(players[0]);
          setCurrentPlayerLoading(false);
        },
        (err) => {
          setError(err);
        }
      );

    return () => unsubscribe();
  };

  useEffect(fetchCurrentPlayer, [currentUser]);

  error && console.log(error);

  if (!currentUser) {
    return <Login />;
  }

  return (
    !currentPlayerLoading && (
      <>
        <Header />
        <div>
          <div className="Config-content">
            <h1 className="Config-title">How many players?</h1>
            <button
              className="Config-button"
              onClick={() => onSizeSelect(2, { aiPlayer: true })}
            >
              AI player
            </button>
            <br />
            <br />
            {_.range(1, GAME_MAX_PLAYERS).map((i) => {
              return (
                <React.Fragment key={i}>
                  <button
                    className="Config-button"
                    onClick={() => onSizeSelect(i + 1, { aiPlayer: false })}
                  >
                    {i + 1} Players
                  </button>
                  <br />
                  <br />
                </React.Fragment>
              );
            })}
            <br />
          </div>
        </div>
      </>
    )
  );
}
