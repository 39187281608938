import _ from "lodash";

import {
  BOARD_STATES,
  ACTIONS,
  PIRATES,
  CARD_ID_TO_TYPE,
  PLAY_STATES,
} from "../../../constants";
import { getLastPlaydCard } from "./";

const generatePlayerUiState = (player) => {
  return {
    actions: player.cards.map(() => PLAY_STATES.NOT_PLAYABLE),
    pirates: player.pirates.map(() => PLAY_STATES.NOT_PLAYABLE),
    board: PLAY_STATES.NOT_PLAYABLE,
  };
};

const createDefaultUiState = (G) => {
  const { players } = G;

  return {
    players: _.mapValues(players, generatePlayerUiState),
    pirateBoard: G.pirateBoard.map((_) => PLAY_STATES.NOT_PLAYABLE),
    playedCards: G.playedCards.map((_) => PLAY_STATES.NOT_PLAYABLE),
    explanations: {}, // id -> explanation
  };
};

const convertPlayableToClickable = (state) => {
  const mapper = (state) =>
    state === PLAY_STATES.PLAYABLE ? PLAY_STATES.CLICKABLE : state;

  state.pirateBoard = state.pirateBoard.map(mapper);
  state.playedCards = state.playedCards.map(mapper);

  state.players = _.mapValues(state.players, (p) => ({
    actions: p.actions.map(mapper),
    pirates: p.pirates.map(mapper),
    board: mapper(p.board),
  }));

  return state;
};

const getPirateFromPlayer = (G, playerID, pirateId) => {
  const player = G.players[playerID];
  return player.pirates.find((p) => p.type === pirateId);
};

const filterPlayersForGoldSteal = (G, currentPlayer) => ([
  playerID,
  player,
]) => {
  if (playerID === currentPlayer) {
    return false;
  }

  const mary = getPirateFromPlayer(G, playerID, PIRATES.maryread);
  if (mary && mary.enabled) {
    return false;
  }

  return player.doubloons > 0;
};

const getPlayersForGoldSteal = (G, currentPlayer) =>
  Object.entries(G.players).filter(filterPlayersForGoldSteal(G, currentPlayer));

const applyChoosableForGoldSteal = (G, state, _uiState, playerID) => {
  getPlayersForGoldSteal(G, playerID).forEach(([pId, _p]) => {
    state.players[pId].board = PLAY_STATES.CHOOSABLE;
  });

  return state;
};

const filterPlayersForCardSteal = (G, currentPlayer) => ([
  playerID,
  player,
]) => {
  if (playerID === currentPlayer) {
    return false;
  }

  const grace = getPirateFromPlayer(G, playerID, PIRATES.graceomalley);
  if (grace && grace.enabled) {
    return false;
  }

  return player.cards.length > 0;
};

const getPlayersForCardSteal = (G, currentPlayer) =>
  Object.entries(G.players).filter(filterPlayersForCardSteal(G, currentPlayer));

const applyChoosableForCardSteal = (G, state, _uiState, playerID) => {
  getPlayersForCardSteal(G, playerID).forEach(([pId, _p]) => {
    state.players[pId].board = PLAY_STATES.CHOOSABLE;
  });

  return state;
};

const applyChoosableForCommandeerPirate = (G, state, uiState, playerID) => {
  Object.entries(G.players).forEach(([playerIdx, player]) => {
    if (playerIdx === playerID) {
      player.pirates.forEach((_pirate, pirateIdx) => {
        state.players[playerIdx].pirates[pirateIdx] = PLAY_STATES.NOT_PLAYABLE;
      });
    } else {
      player.pirates.forEach((pirate, pirateIdx) => {
        const isPiratePlayable = isCardPlayable(
          G,
          uiState,
          state,
          playerID,
          pirate
        );

        if (isPiratePlayable) {
          state.players[playerIdx].pirates[pirateIdx] = PLAY_STATES.CHOOSABLE;
        }
      });
    }
  });

  state.pirateBoard = state.pirateBoard.map((_p) => PLAY_STATES.NOT_PLAYABLE);

  return state;
};

const applyChoosableForBartholomewRoberts = (G, state, _uiState, playerID) => {
  state.pirateBoard = state.pirateBoard.map(() => PLAY_STATES.CHOOSABLE);

  Object.entries(G.players).forEach(([playerIdx, p]) => {
    p.pirates.forEach((_pirate, pirateIdx) => {
      state.players[playerIdx].pirates[pirateIdx] = PLAY_STATES.NOT_PLAYABLE;
    });
  });

  return state;
};

const applyChoosableForMarooned = (G, state, _uiState, playerID) => {
  Object.entries(G.players).forEach(([pId, p]) => {
    if (pId === playerID) {
      return;
    }

    if (p.pirates.length > 0) {
      state.players[pId].board = PLAY_STATES.CHOOSABLE;
    }
  });

  return state;
};

const applyChoosableForWilliamKid = (G, state, uiState, playerID) => {
  G.playedCards.forEach((card, cardIdx) => {
    // True is passed to bypass the 3 action card limit. We check if isKidd === true.
    const isPlayedCardReplayable = isCardPlayable(
      G,
      uiState,
      state,
      playerID,
      card,
      true
    );

    if (isPlayedCardReplayable) {
      state.playedCards[cardIdx] = PLAY_STATES.CHOOSABLE;
    }
  });

  return state;
};

const applyChoosableForPlayerPirates = (G, state, _uiState, _playerID) => {
  Object.entries(G.players).forEach(([playerIdx, p]) => {
    p.pirates.forEach((_pirate, pirateIdx) => {
      state.players[playerIdx].pirates[pirateIdx] = PLAY_STATES.CHOOSABLE;
    });
  });

  state.pirateBoard = state.pirateBoard.map(() => PLAY_STATES.NOT_PLAYABLE);

  return state;
};

const applyChoosableForBarter = (G, state, _uiState, _playerID) => {
  Object.entries(G.players).forEach(([playerIdx, p]) => {
    p.pirates.forEach((pirate, pirateIdx) => {
      if (pirate.cost === 0) {
        state.players[playerIdx].pirates[pirateIdx] = PLAY_STATES.NOT_PLAYABLE;
      } else {
        state.players[playerIdx].pirates[pirateIdx] = PLAY_STATES.CHOOSABLE;
      }
    });
  });

  Object.entries(G.pirateBoard).forEach(([pirateIdx, pirate]) => {
    pirate.cost === 0
      ? (state.pirateBoard[pirateIdx] = PLAY_STATES.NOT_PLAYABLE)
      : (state.pirateBoard[pirateIdx] = PLAY_STATES.CHOOSABLE);
  });

  return state;
};

const mutinyCostFilter = (G, uiState) => {
  const selectedPirateIds = _.filter(
    uiState.selectedIds,
    (id) => id[0] === "P"
  );
  const selectedPirate = _.flatMap([
    ...Object.values(G.players).map((p) => p.pirates),
    ...G.pirateBoard,
  ]).filter((p) => p.id === selectedPirateIds[0])[0];

  if (selectedPirateIds.length === 0) {
    return () => true;
  }

  return (pirate) => {
    return pirate.cost === selectedPirate.cost;
  };
};

const applyChoosableForMutiny = (G, state, uiState, playerID) => {
  const filterFn = mutinyCostFilter(G, uiState);
  const yourPirateIds = G.players[playerID].pirates.map((p) => p.id);
  const yourPirateSelected =
    _.intersection(yourPirateIds, uiState.selectedIds).length > 0;

  Object.entries(G.players).forEach(([playerIdx, p]) => {
    p.pirates.forEach((pirate, pirateIdx) => {
      if (uiState.selectedIds.includes(pirate.id)) {
        state.players[playerIdx].pirates[pirateIdx] = PLAY_STATES.NOT_PLAYABLE;
        return;
      }

      if (
        !filterFn(pirate) || // filter for same cost
        (playerIdx === playerID && yourPirateSelected) // you've selected a pirate
      ) {
        state.players[playerIdx].pirates[pirateIdx] = PLAY_STATES.NOT_PLAYABLE;
        return;
      }

      state.players[playerIdx].pirates[pirateIdx] = PLAY_STATES.CHOOSABLE;
    });
  });

  state.pirateBoard = G.pirateBoard.map((pirate) => {
    if (uiState.selectedIds.includes(pirate.id)) {
      return PLAY_STATES.NOT_PLAYABLE;
    }

    return filterFn(pirate) ? PLAY_STATES.CHOOSABLE : PLAY_STATES.NOT_PLAYABLE;
  });

  return state;
};

const applyChoosableForChingShih = (
  G,
  state,
  uiState,
  executionState,
  playerID
) => {
  if (executionState === BOARD_STATES.WAIT_FOR_ACTION_SELECT) {
    state.players[playerID].actions = state.players[playerID].actions.map(
      () => PLAY_STATES.CHOOSABLE
    );
  } else if (executionState === BOARD_STATES.WAIT_FOR_PLAYER_SELECT) {
    state = applyChoosableForGoldSteal(G, state, uiState, playerID);
  }

  return state;
};

const applyChoosableForSayyida = (state, executionState, playerID) => {
  if (executionState === BOARD_STATES.WAIT_FOR_ACTION_SELECT) {
    state.players[playerID].actions = state.players[playerID].actions.map(
      () => PLAY_STATES.CHOOSABLE
    );
  }

  return state;
};

const applyChoosableForBarbarossa = (state, executionState, playerID) => {
  if (executionState === BOARD_STATES.WAIT_FOR_ACTION_SELECT) {
    state.players[playerID].actions = state.players[playerID].actions.map(
      () => PLAY_STATES.CHOOSABLE
    );
  }

  return state;
};

const applyChoosableHighlights = (
  G,
  state,
  uiState,
  selectedCardType,
  executionState,
  playerID
) => {
  if (executionState === BOARD_STATES.WAIT_FOR_SELL) {
    state = createDefaultUiState(G);
    state.players[playerID].pirates = state.players[playerID].pirates.map(
      () => PLAY_STATES.CHOOSABLE
    );
    return state;
  }

  if (
    [BOARD_STATES.WAIT_FOR_PLAY, BOARD_STATES.WAIT_FOR_HIRE].includes(
      executionState
    )
  ) {
    // don't highlight if the player has not pressed play yet, or needs to hire, etc.
    return state;
  }

  if (!selectedCardType) {
    return state;
  }

  // Playable = green highlight
  // Clickable = no highlight, but you can click on it to reset state
  // We want to remove the green highlights to make the blue stand out
  state = convertPlayableToClickable(state);

  switch (selectedCardType) {
    case ACTIONS.pillage:
      state = applyChoosableForGoldSteal(G, state, uiState, playerID);
      break;
    case ACTIONS.commandeer:
      state = applyChoosableForCommandeerPirate(G, state, uiState, playerID);
      break;
    case ACTIONS.plunder:
      state = applyChoosableForCardSteal(G, state, uiState, playerID);
      break;
    case ACTIONS.marooned:
      state = applyChoosableForMarooned(G, state, uiState, playerID);
      break;
    case ACTIONS.walktheplank:
      state = applyChoosableForPlayerPirates(G, state, uiState, playerID);
      break;
    case ACTIONS.barter:
      state = applyChoosableForBarter(G, state, uiState, playerID);
      break;
    case ACTIONS.mutiny:
      state = applyChoosableForMutiny(G, state, uiState, playerID);
      break;
    case PIRATES.hendrickquintor:
      state = applyChoosableForCommandeerPirate(G, state, uiState, playerID);
      break;
    case PIRATES.chingshih:
      state = applyChoosableForChingShih(
        G,
        state,
        uiState,
        executionState,
        playerID
      );
      break;
    case PIRATES.blackbeard:
      state = applyChoosableForPlayerPirates(G, state, uiState, playerID);
      break;
    case PIRATES.bartholomewroberts:
      state = applyChoosableForBartholomewRoberts(G, state, uiState, playerID);
      break;
    case PIRATES.henrymorgan:
      state = applyChoosableForCardSteal(G, state, uiState, playerID);
      break;
    case PIRATES.sayyidaalhurra:
      state = applyChoosableForSayyida(state, executionState, playerID);
      break;
    case PIRATES.barbarossa:
      state = applyChoosableForBarbarossa(state, executionState, playerID);
      break;
    case PIRATES.williamkid:
      state = applyChoosableForWilliamKid(G, state, uiState, playerID);
      break;
    case ACTIONS.fireinthehole:
    case ACTIONS.xmarksthespot:
    case ACTIONS.yohoho:
    case ACTIONS.coffer:
    case ACTIONS.scallywag:
    case PIRATES.annebonny:
    case PIRATES.kanhojiangre:
    case PIRATES.rachelwall:
    default:
      console.log(`Handling ${selectedCardType} has not been implemented`);
  }

  // make selected card not playable
  state.players[playerID].actions.forEach((_actionState, aIdx) => {
    const card = G.players[playerID].cards[aIdx];
    if (card.id === uiState.selectedCardId) {
      state.players[playerID].actions[aIdx] = PLAY_STATES.NOT_PLAYABLE;
    }
  });

  return state;
};

/* Compute playable */
const isCardPlayable = (
  G,
  uiState,
  derivedState,
  playerID,
  card,
  isKidd = false
) => {
  const otherPlayers = _.omit(G.players, playerID);
  const you = G.players[playerID];
  const otherPlayerPirates = _.flatten(
    Object.values(otherPlayers).map((p) => p.pirates)
  );
  const playedMaxActionCards = G.playedCards.length >= 3;

  const setExplanation = (text) => {
    derivedState.explanations[card.id] = text;
  };

  if (card.enabled === false) {
    setExplanation("You cannot play disabled pirates");
    return false;
  }
  if (card.played === true) {
    setExplanation("This pirate has already been played this turn.");
    return false;
  }

  if (card.class === "action" && !isKidd && playedMaxActionCards) {
    setExplanation("You can play up to 3 action cards per turn.");
    return false;
  }

  // if (uiState.selectedIds.includes(card.id)) {
  //   return false;
  // }

  const anyPlayersToStealGoldFrom =
    getPlayersForGoldSteal(G, playerID).length > 0;
  const anyPlayersToStealCardsFrom =
    getPlayersForCardSteal(G, playerID).length > 0;
  const otherPlayersHavePirates = _.some(
    otherPlayers,
    (p) => p.pirates.length > 0
  );
  const anyEnabledOtherPlayerPirates = _.some(
    otherPlayerPirates,
    (p) => p.enabled
  );
  const anyPlayableOtherPlayerPirates = _.some(
    otherPlayerPirates,
    (p) =>
      !p.played &&
      p.type !== PIRATES.graceomalley &&
      p.type !== PIRATES.maryread
  );
  const otherPlayersHaveCards = _.some(otherPlayers, (p) => p.cards.length > 0);

  const yourCards = _.filter(you.cards, (c) => c.id !== uiState.selectedCardId);
  const youHaveACard = yourCards.length > 0;
  const youHaveGold = you.doubloons > 0;
  const youHavePirates = you.pirates.length > 0;

  const lastPlayedCard = getLastPlaydCard(G);

  switch (card.type) {
    case ACTIONS.pillage:
      if (!anyPlayersToStealGoldFrom) {
        setExplanation("No players have gold to steal");
      }
      return anyPlayersToStealGoldFrom;
    case ACTIONS.commandeer:
    case PIRATES.hendrickquintor:
      if (!otherPlayersHavePirates) {
        setExplanation("No other players have pirates");
        return false;
      }

      if (!anyEnabledOtherPlayerPirates) {
        setExplanation("There are no enabled pirates available");
        return false;
      }

      if (!anyPlayableOtherPlayerPirates) {
        setExplanation("There are no playable pirates available");
        return false;
      }

      return true;
    case ACTIONS.mutiny:
      const canMutiny = youHavePirates || otherPlayersHavePirates;
      if (!canMutiny) {
        setExplanation("There are no pirates to mutiny");
      }
      return canMutiny;
    case ACTIONS.marooned:
      if (!otherPlayersHavePirates) {
        setExplanation("No other players have pirates");
      }
      return otherPlayersHavePirates;
    case ACTIONS.plunder:
    case PIRATES.henrymorgan:
      if (!anyPlayersToStealCardsFrom && otherPlayersHaveCards) {
        setExplanation("Can't steal from someone with with Grace O' Malley");
      } else if (!anyPlayersToStealCardsFrom) {
        setExplanation("Other players have no cards to steal");
      }
      return anyPlayersToStealCardsFrom;
    case PIRATES.chingshih:
      if (!youHaveACard) {
        setExplanation("You have no cards to discard");
        return false;
      }

      if (!anyPlayersToStealGoldFrom) {
        setExplanation("No players to steal from");
        // TODO : split into mary read reason and no-gold reason
        return false;
      }

      return true;
    case ACTIONS.scallywag:
    case PIRATES.samuelbellamy:
    case PIRATES.bartholomewroberts:
      return true;
    case PIRATES.sayyidaalhurra:
    case PIRATES.barbarossa:
      if (!youHaveACard) {
        setExplanation("You have no cards to discard");
      }
      return youHaveACard;
    case PIRATES.cheungpotsai:
      if (!youHaveGold) {
        setExplanation("You need at least one doubloon to play Cheung Po Tsai");
      }
      return youHaveGold;
    case PIRATES.rachelwall:
      if (yourCards.length >= 5) {
        setExplanation("You need 4 or fewer cards to play Rachel Wall");
      }
      return yourCards.length < 5;
    case PIRATES.kanhojiangre:
      if (yourCards.length >= 4) {
        setExplanation("You need 3 or fewer cards to play Kanhoji Angre");
      }
      return yourCards.length < 4;
    case PIRATES.williamkid:
      //Check if any of the played cards are playable again. isKidd is passed to ignore 3 card action card limit.
      const playedCardIsReplayable = _.some(G.playedCards, (card) =>
        isCardPlayable(
          G,
          uiState,
          derivedState,
          playerID,
          card,
          (isKidd = true)
        )
      );
      if (!lastPlayedCard) {
        setExplanation(`You must have played a card to play ${card.type}`);
      } else if (!playedCardIsReplayable) {
        setExplanation(`None of your played action cards are re-playable`);
      }
      return playedCardIsReplayable;
    case ACTIONS.fireinthehole:
      const lastPlayedCardIsPlayable =
        lastPlayedCard &&
        isCardPlayable(G, uiState, derivedState, playerID, lastPlayedCard);
      if (!lastPlayedCard) {
        setExplanation(`You must have played a card to play ${card.type}`);
      } else if (!lastPlayedCardIsPlayable) {
        setExplanation(`${lastPlayedCard.type} is not playable`);
      }
      return lastPlayedCardIsPlayable;
    case PIRATES.graceomalley:
    case PIRATES.maryread:
      return false;
    case PIRATES.blackbeard:
      const anyEnabledPirates = _.some(otherPlayerPirates, (p) => p.enabled);
      if (!anyEnabledPirates) {
        setExplanation("There are no enabled pirates to disable.");
      }
      return anyEnabledPirates;
    case PIRATES.annebonny:
    case PIRATES.blackcaesar:
      return true;
    case ACTIONS.walktheplank:
      const anyPiratesToWalk = youHavePirates || otherPlayerPirates.length > 0;
      if (!anyPiratesToWalk) {
        setExplanation("There are no pirates to walk the plank!");
      }
      return anyPiratesToWalk;
    case ACTIONS.xmarksthespot:
    case ACTIONS.yohoho:
    case ACTIONS.coffer:
      return true;
    default:
      return true;
  }
};

const applyPlayableCardHighlights = (G, uiState, derivedState, playerID) => {
  const actions = G.players[playerID].cards;
  const pirates = G.players[playerID].pirates;

  const player = derivedState.players[playerID];

  player.actions = player.actions.map((_action, i) => {
    const card = actions[i];
    return isCardPlayable(G, uiState, derivedState, playerID, card)
      ? PLAY_STATES.PLAYABLE
      : PLAY_STATES.NOT_PLAYABLE;
  });

  player.pirates = player.pirates.map((_pirate, i) => {
    const card = pirates[i];
    return isCardPlayable(G, uiState, derivedState, playerID, card)
      ? PLAY_STATES.PLAYABLE
      : PLAY_STATES.NOT_PLAYABLE;
  });

  derivedState.pirateBoard = G.pirateBoard.map((pirate) => {
    return pirate.cost <= G.players[playerID].doubloons
      ? PLAY_STATES.PLAYABLE
      : PLAY_STATES.NOT_PLAYABLE;
  });

  derivedState.playedCards = G.playedCards.map(() => {
    return PLAY_STATES.NOT_PLAYABLE;
  });

  return derivedState;
};

export default function deriveUiState(
  G,
  uiState,
  playerID,
  isCurrentPlayer = true
) {
  let derivedState = createDefaultUiState(G);

  const cardId = uiState.chainedSelectedCardId || uiState.selectedCardId;
  const selectedCardType = CARD_ID_TO_TYPE[cardId];

  const executionState = uiState.executionQueue[0]?.state;

  derivedState = applyPlayableCardHighlights(
    G,
    uiState,
    derivedState,
    playerID
  );

  derivedState = applyChoosableHighlights(
    G,
    derivedState,
    uiState,
    selectedCardType,
    executionState,
    playerID
  );

  if (!isCurrentPlayer) {
    derivedState.players[playerID].actions = derivedState.players[
      playerID
    ].actions.map(() => PLAY_STATES.NOT_PLAYABLE);
    derivedState.players[playerID].pirates = derivedState.players[
      playerID
    ].pirates.map(() => PLAY_STATES.NOT_PLAYABLE);
    derivedState.pirateBoard = derivedState.pirateBoard.map(
      () => PLAY_STATES.NOT_PLAYABLE
    );
  }

  return derivedState;
}
