import React, { useState, useEffect } from "react";
import "./styles/header.css";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";

export default function Header() {
  // const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  // const toggleNav = () => {
  //   setNavVisibility(!isNavVisible);
  // };

  return (
    <header className="Header">
      <Link to="/" style={{ width: "200px" }}>
        <img
          src={require("../assets/wordmark.png")}
          className="Logo"
          alt="logo"
        />
      </Link>
      <CSSTransition
        in={!isSmallScreen || false}
        timeout={350}
        classNames="NavAnimation"
        unmountOnExit
      >
        <nav className="Nav">
          <a href="https://gum.co/NQnzZ">Tabletop</a>
          <a href="mailto:hello@playdoubloons.com">Feedback</a>
          <a href="https://discord.gg/xja9TK6t4j">Discord</a>
          <a href="https://tabletop.playdoubloons.com/blog">Blog</a>
        </nav>
      </CSSTransition>
    </header>
  );
}
