import React from "react";
import classNames from "classnames";
import _ from "lodash";

import ScoreBoard from "./ScoreBoard";
import CardContainer, {
  CARDS_DISPLAY_OVERLAP,
  CARDS_DISPLAY_SPLAY,
} from "./CardContainer";
import { CARD_TYPE_ACTION, CARD_TYPE_PIRATE } from "./Card";

import "./PlayerSection.scss";
import { PLAY_STATES } from "../../constants";

/**
 *  PlayerSection contains everything relevant to a player
 * - their hand (face up if you, face down if opponents)
 * - their doubloons
 * - their pirates
 */
export default function PlayerSection({
  playerId,
  playerName,
  player,
  isActive = false,
  isMe = false,
  onBoardClick,
  onPlayCard,
  onActionClick = () => {},
  onPirateClick = () => {},
  uiState,
  explanations,
  volume,
  // will get refactored
  pirateSelected,
  handSelected,
}) {
  const iAmActive = isActive && isMe;
  const playState = _.get(uiState, "board");

  return (
    <div
      className={classNames("player-section", {
        [playState]: playState,
      })}
      onClick={(e) => {
        if (playState !== PLAY_STATES.NOT_PLAYABLE) {
          onBoardClick();
          e.stopPropagation();
        }
      }}
    >
      {/* pirates */}
      <CardContainer
        cards={player?.pirates}
        selectedCardIds={iAmActive ? pirateSelected : []}
        faceUp
        onPlayCard={onPlayCard}
        onClick={onPirateClick}
        displayType={CARDS_DISPLAY_SPLAY}
        type={CARD_TYPE_PIRATE}
        uiState={uiState?.pirates}
        explanations={explanations}
        volume={volume}
      />
      {/* action cards */}
      <CardContainer
        cards={player?.cards}
        selectedCardIds={iAmActive ? handSelected : []}
        faceUp={isMe}
        uiState={uiState?.actions}
        onPlayCard={onPlayCard}
        onClick={onActionClick}
        displayType={CARDS_DISPLAY_OVERLAP}
        type={CARD_TYPE_ACTION}
        explanations={explanations}
        volume={volume}
      />
      {/* doubloons */}
      <ScoreBoard
        score={player?.doubloons}
        playerName={playerName}
        playerId={playerId}
      />
    </div>
  );
}
