import { BLOCK_PLAY_UNTIL_SELL, UNBLOCK_PLAY_AFTER_SELL } from "./store";
import { BOARD_STATES, MAX_PIRATES } from "../../constants";

export const blockPlayIfTooManyPirates = (player, uiState, dispatch) => {
  const execution = uiState.executionQueue[0];
  if (
    player.pirates.length > MAX_PIRATES &&
    execution?.state !== BOARD_STATES.WAIT_FOR_SELL
  ) {
    dispatch({ type: BLOCK_PLAY_UNTIL_SELL });
  }
};

export const unblockPlayIfPossible = (player, uiState, dispatch) => {
  const execution = uiState.executionQueue[0];
  if (
    player.pirates.length <= MAX_PIRATES &&
    execution?.state === BOARD_STATES.WAIT_FOR_SELL
  ) {
    dispatch({ type: UNBLOCK_PLAY_AFTER_SELL });
  }
};
