import {
  ACTIONS,
  PIRATES,
  BOARD_STATES,
  CARD_ID_TO_TYPE,
} from "../../../constants";

const noop = () => null;

const NO_FURTHER_INPUT_REQUIRED = {
  selectionQueue: [],
  composePayload: noop,
};

const SELECT_ONE_PLAYER = {
  selectionQueue: [{ state: BOARD_STATES.WAIT_FOR_PLAYER_SELECT }],
  composePayload: (results) => ({
    onPlayerId: results[0],
  }),
};

const SELECT_PIRATE_AND_CHAIN = {
  selectionQueue: [
    {
      state: BOARD_STATES.WAIT_FOR_PIRATE_SELECT,
      chainSelection: true,
    },
  ],
  composePayload: (results) => {
    const [pirateId, ...otherResults] = results;
    const pirateType = CARD_ID_TO_TYPE[pirateId];

    const piratePayload = properties[pirateType].composePayload(otherResults);

    return {
      pirateId,
      piratePayload,
    };
  },
};

const SELECT_BOARD_AND_CHAIN = {
  selectionQueue: [
    {
      state: BOARD_STATES.WAIT_FOR_BOARD_SELECT,
      chainSelection: true,
    },
  ],
  composePayload: (results) => {
    const [actionId, ...otherResults] = results;
    const actionType = CARD_ID_TO_TYPE[actionId];

    const actionPayload = properties[actionType].composePayload(otherResults);

    return {
      actionId,
      actionPayload,
    };
  },
};

const PLAY_LAST_PLAYED = {
  chainLastPlayed: true,
  selectionQueue: [],
  composePayload: (results) => {
    const [cardId, ...otherResults] = results;
    const cardType = CARD_ID_TO_TYPE[cardId];

    const cardPayload = properties[cardType].composePayload(otherResults);

    return {
      cardId,
      cardPayload,
    };
  },
};

export const shouldChainStates = (cardType) => {
  return [ACTIONS.commandeer, PIRATES.hendrickquintor].includes(cardType);
};

const properties = {
  [ACTIONS.mutiny]: {
    selectionQueue: [
      { state: BOARD_STATES.WAIT_FOR_PIRATE_SELECT },
      { state: BOARD_STATES.WAIT_FOR_PIRATE_SELECT },
    ],
    composePayload: (results) => ({
      firstPirateId: results[0],
      secondPirateId: results[1],
    }),
  },
  [ACTIONS.barter]: {
    selectionQueue: [{ state: BOARD_STATES.WAIT_FOR_PIRATE_SELECT }],
    composePayload: (results) => ({
      pirateId: results[0],
    }),
  },
  [ACTIONS.walktheplank]: {
    selectionQueue: [{ state: BOARD_STATES.WAIT_FOR_PIRATE_SELECT }],
    composePayload: (results) => ({
      pirateId: results[0],
    }),
  },
  [PIRATES.chingshih]: {
    selectionQueue: [
      { state: BOARD_STATES.WAIT_FOR_ACTION_SELECT },
      { state: BOARD_STATES.WAIT_FOR_PLAYER_SELECT },
    ],
    composePayload: (results) => ({
      actionToDiscard: results[0],
      onPlayerId: results[1],
    }),
  },
  [PIRATES.sayyidaalhurra]: {
    selectionQueue: [{ state: BOARD_STATES.WAIT_FOR_ACTION_SELECT }],
    composePayload: (results) => ({
      actionToDiscard: results[0],
    }),
  },
  [PIRATES.barbarossa]: {
    selectionQueue: [{ state: BOARD_STATES.WAIT_FOR_ACTION_SELECT }],
    composePayload: (results) => ({
      actionToDiscard: results[0],
    }),
  },
  [PIRATES.bartholomewroberts]: {
    selectionQueue: [{ state: BOARD_STATES.WAIT_FOR_PIRATE_SELECT }],
    composePayload: (results) => ({
      pirateId: results[0],
    }),
  },
  [PIRATES.blackbeard]: {
    selectionQueue: [
      {
        state: BOARD_STATES.WAIT_FOR_PIRATE_SELECT,
      },
    ],
    composePayload: (results) => ({
      pirateId: results[0],
    }),
  },
  [ACTIONS.pillage]: SELECT_ONE_PLAYER,
  [ACTIONS.plunder]: SELECT_ONE_PLAYER,
  [ACTIONS.marooned]: SELECT_ONE_PLAYER,
  [ACTIONS.commandeer]: SELECT_PIRATE_AND_CHAIN,
  [ACTIONS.fireinthehole]: PLAY_LAST_PLAYED,
  [ACTIONS.xmarksthespot]: NO_FURTHER_INPUT_REQUIRED,
  [ACTIONS.yohoho]: NO_FURTHER_INPUT_REQUIRED,
  [ACTIONS.coffer]: NO_FURTHER_INPUT_REQUIRED,
  [ACTIONS.scallywag]: NO_FURTHER_INPUT_REQUIRED,
  [PIRATES.williamkid]: SELECT_BOARD_AND_CHAIN,
  [PIRATES.hendrickquintor]: SELECT_PIRATE_AND_CHAIN,
  [PIRATES.annebonny]: NO_FURTHER_INPUT_REQUIRED,
  [PIRATES.blackcaesar]: NO_FURTHER_INPUT_REQUIRED,
  [PIRATES.kanhojiangre]: NO_FURTHER_INPUT_REQUIRED,
  [PIRATES.rachelwall]: NO_FURTHER_INPUT_REQUIRED,
  [PIRATES.henrymorgan]: SELECT_ONE_PLAYER,
  [PIRATES.cheungpotsai]: NO_FURTHER_INPUT_REQUIRED,
  [PIRATES.samuelbellamy]: NO_FURTHER_INPUT_REQUIRED,
};

export default Object.freeze(properties);
