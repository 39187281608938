import React, { useEffect, useContext, useState } from "react";
import firebase from "../firebase";
import "./GamePage.css";
import wordmark from "../assets/wordmark.png";
import { AuthContext } from "../firebase/authentication";
import Login from "./Login";
import Board from "../components/Board";
import { LOBBY_URL } from "../config";

export default function GamePage({ match }) {
  const { currentUser } = useContext(AuthContext);
  const {
    params: { gameId },
  } = match;

  // Game document
  const [gameLoading, setGameLoading] = useState(true);
  const [game, setGame] = useState(null);

  // All players in the game document
  const [playersLoading, setPlayersLoading] = useState(true);
  const [players, setPlayers] = useState(null);

  // Current player based on auth
  const [currentPlayerLoading, setCurrentPlayerLoading] = useState(true);
  const [currentPlayer, setCurrentPlayer] = useState(null);

  // Error state
  const [error, setError] = useState(false);

  const [copied, setCopy] = useState(false);
  const gameUrl = `${LOBBY_URL}/game/${gameId}`;

  const getPlayerItem = (playerId) => {
    if (playerId && players) {
      if (playerId === currentUser.uid) {
        return (
          <div className="player-item">
            {players.find((el) => el.uid === playerId)?.nickname} - You
            <div className="player-ready" />
          </div>
        );
      }
      return (
        <div className="player-item">
          {players.find((el) => el.uid === playerId)?.nickname}
          <div className="player-ready" />
        </div>
      );
    }
  };

  const fetchGames = () => {
    const unsubscribe = firebase.db
      .collection("games")
      .where("code", "==", gameId)
      .onSnapshot(
        (snapshot) => {
          const games = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setGame(games[0]);
          setGameLoading(false);
        },
        (err) => {
          setError(err);
        }
      );

    return () => unsubscribe();
  };

  const fetchPlayers = () => {
    const unsubscribe = firebase.db
      .collection("players")
      .where("games", "array-contains", gameId)
      .onSnapshot(
        (snapshot) => {
          const players = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setPlayers(players);
          setPlayersLoading(false);
        },
        (err) => {
          setError(err);
        }
      );

    return () => unsubscribe();
  };

  const fetchCurrentPlayer = () => {
    if (!currentUser) {
      return;
    }
    const unsubscribe = firebase.db
      .collection("players")
      .where("uid", "==", currentUser.uid)
      .onSnapshot(
        (snapshot) => {
          const players = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCurrentPlayer(players[0]);
          setCurrentPlayerLoading(false);
        },
        (err) => {
          setError(err);
        }
      );

    return () => unsubscribe();
  };

  const handleJoin = async () => {
    // on join game, add userId to games doc if not already there

    if (
      !Object.keys(game.players)
        .map((el) => el.id)
        .includes(currentUser.uid)
    ) {
      const newPlayers = game.players;

      newPlayers[currentUser.uid] = {
        pirates: [],
        cards: [],
        doubloons: 0,
        currentPlayer: false,
      };

      await firebase.db.collection("games").doc(game.id).update({
        players: newPlayers,
      });
    }

    // on join game, add gameId to user doc if not already there

    if (!currentPlayer.games.includes(gameId)) {
      await firebase.db
        .collection("players")
        .doc(currentPlayer.id)
        .update({
          games: [...currentPlayer.games, gameId],
        });
    }
  };

  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    textField.innerText = gameUrl;
    textField.style.opacity = "0";
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopy(true);
    setTimeout(
      function () {
        setCopy(false);
      }.bind(),
      2000
    );
  };

  useEffect(fetchGames, []);
  useEffect(fetchPlayers, []);
  useEffect(fetchCurrentPlayer, [currentUser]);

  error && console.log(error);

  if (!currentUser) {
    return <Login />;
  }

  if (game?.status === "joinable") {
    return (
      !gameLoading &&
      !playersLoading &&
      !currentPlayerLoading && (
        <div className="Game">
          <header className="Game-header">
            <img src={wordmark} className="Game-logo" alt="logo" />
          </header>
          <div className="flex fd-col align-center">
            {game.players.hasOwnProperty(currentUser.uid) ? (
              <h3>You joined the game!</h3>
            ) : (
              <div>
                <button className="join-game-button" onClick={handleJoin}>
                  Join Game
                </button>
              </div>
            )}
            <div>Invite others by sending them the link below</div>
            <div className="game-link">
              <div className="game-link-box">
                {`${LOBBY_URL}/game/${gameId}`}
              </div>
              <div className="game-link-button" onClick={copyToClipboard}>
                {copied ? "Copied️!" : " Copy "}
              </div>
            </div>
            <div>
              Game Code
              <br /> <div className="game-code">{gameId}</div>
            </div>
            <div className="player-list">
              {Object.keys(game.players).map((p) => {
                return <div key={p}>{getPlayerItem(p)}</div>;
              })}
            </div>
            <div>
              <br />
              The game will begin once all players join!
            </div>
          </div>
        </div>
      )
    );
  }

  if (game?.status === "playing" || game?.status === "complete") {
    return (
      !gameLoading &&
      !playersLoading &&
      !currentPlayerLoading && (
        <Board
          game={game}
          gamePlayers={players}
          currentPlayer={currentPlayer}
          me={currentUser}
        />
      )
    );
  }

  return null;
}
