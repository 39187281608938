import React, { useEffect } from "react";
import "./styles/button.css";
import useSound from "use-sound";
import endturn from "../assets/sounds/bell.wav";

export default function PlayerMoves({
  active,
  onEndTurn,
  activePlayer,
  currentPlayerName,
  gameOver,
  volume,
  chat,
  myUid,
}) {
  const [endturnSound] = useSound(endturn, { volume: volume });

  useEffect(() => {
    endturnSound();
    // eslint-disable-next-line
  }, [activePlayer]);

  const lastPlayerAction = chat.slice(-1)?.[0]?.action;

  return (
    <>
      {!gameOver ? (
        <div>
          {activePlayer ? (
            <div>
              <button
                className="deep-button"
                disabled={!active.endTurn}
                onClick={() => {
                  onEndTurn();
                }}
              >
                End Turn
              </button>
            </div>
          ) : (
            <div>
              <button className="deep-button" disabled>
                {lastPlayerAction && lastPlayerAction.uid !== myUid
                  ? `${currentPlayerName} ${lastPlayerAction}`
                  : `${currentPlayerName}'s turn`}
              </button>
            </div>
          )}
        </div>
      ) : (
        <div>
          <button className="deep-button" disabled>
            Game Over
          </button>
        </div>
      )}
    </>
  );
}
