import React from "react";
import _ from "lodash";
import classNames from "classnames";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Card, { CARD_TYPE_ACTION } from "./Card";
import CardSlot from "./CardSlot";

export const CARDS_DISPLAY_DECK = "deck";
export const CARDS_DISPLAY_SPLAY = "splay";
export const CARDS_DISPLAY_OVERLAP = "overlap";

/**
 * CardContainer contains one or more cards.
 * displayType = CARDS_DISPLAY_HAND | CARDS_DISPLAY_SPLAY | CARDS_DISPLAY_OVERLAP
 */
export default function CardContainer({
  cards = [],
  selectedCardIds,
  faceUp,
  cardType = CARD_TYPE_ACTION,
  displayType = CARDS_DISPLAY_OVERLAP,
  onPlayCard,
  onHirePirate,
  onClick,
  length,
  uiState,
  className,
  explanations = {},
  numSlots,
  discardPile,
  volume,
}) {
  if (cards.length === 16) {
    console.log("cards", cards);
  }
  return (
    <TransitionGroup
      className={classNames("card-container", className, displayType)}
    >
      {length && (
        <Card
          key="draw"
          className="card-deck"
          cardType={cardType}
          faceUp={false}
          length={length}
          volume={volume}
        />
      )}
      <div className="card-container__slots">
        {_.range(numSlots).map((i) => (
          <CardSlot key={`slot_${i}`} slotText={i + 1} />
        ))}
      </div>
      {cards.map((card, i) => {
        return (
          <CSSTransition timeout={250} classNames="card" key={card.id}>
            <Card
              // key={card.id}
              card={card}
              selected={selectedCardIds.includes(card.id)}
              faceUp={faceUp}
              cardType={cardType}
              onPlayCard={onPlayCard}
              onHirePirate={onHirePirate}
              onClick={onClick}
              playState={_.get(uiState, i)}
              explanation={explanations[card.id]}
              volume={volume}
            />
          </CSSTransition>
        );
      })}
      {discardPile && (
        <div className="card-container__discard">
          <CardSlot key={`slot_1`} slotText={"Discard \b Pile"} />
          {discardPile && discardPile.length > 0 && (
            <div>
              <div className="card-container__discard-name">Discard Pile</div>
            </div>
          )}
        </div>
      )}
      {discardPile && discardPile.length > 0 && (
        <Card
          key="discard"
          className="card-deck__discard"
          cardType={cardType}
          card={discardPile[discardPile.length - 1]}
          faceUp={true}
          length={length}
          volume={volume}
        />
      )}
    </TransitionGroup>
  );
}
