import React, { Component } from "react";
import "./styles/mobileCover.css";
class MobileCover extends Component {
  state = {};
  render() {
    return (
      <div className="full_height app-section">
        <div className="title">
          <div className="mobile-body">
            Sorry, Doubloons is not currently available on mobile. <br />
            Please join from a desktop browser.
          </div>
        </div>
        <br></br>
        <br></br>
      </div>
    );
  }
}

export default MobileCover;
