import _ from "lodash";
import {
  ACTIONS,
  ACTION_CARD_TYPES,
  CARD_ID_TO_TYPE,
  PIRATES,
  PIRATE_CARD_TYPES,
} from "../constants";

const takeCardsFromDeck = (G, numToTake, deckType = "action") => {
  const deckName = `${deckType}Deck`;
  const discardPileName = `${deckType}DiscardPile`;

  const refillIfEmpty = () => {
    if (G[deckName].length === 0) {
      G[deckName] = _.shuffle(G[discardPileName]);
      G[discardPileName] = [];
    }
  };

  const drawnCards = [];
  while (drawnCards.length < numToTake) {
    refillIfEmpty();

    if (G[deckName].length === 0) {
      throw Error("Attempting to take cards from empty deck");
    }

    // assumption: there is always at least 1 card in the deck, b/c we refill at the end
    drawnCards.push(G[deckName].pop());

    refillIfEmpty();
  }

  return drawnCards;
};

const getHandIdx = (hand, cardId) => {
  return hand.findIndex((c) => c.id === cardId);
};

const takeCardFromHandById = (hand, cardId) => {
  const i = getHandIdx(hand, cardId);
  return hand.splice(i, 1)[0];
};

// Action card effects

const getDeckForPirateId = (G, pirateId) => {
  const playerDecks = Object.values(G.players).map((p) => p.pirates);

  return [...playerDecks, G.pirateBoard].find(
    (deck) => deck.findIndex((pirate) => pirate.id === pirateId) !== -1
  );
};

const applyMutiny = ({ G, payload }) => {
  const { firstPirateId, secondPirateId } = payload;

  const firstDeck = getDeckForPirateId(G, firstPirateId);
  const secondDeck = getDeckForPirateId(G, secondPirateId);

  const firstIdx = getHandIdx(firstDeck, firstPirateId);
  const secondIdx = getHandIdx(secondDeck, secondPirateId);

  firstDeck[firstIdx].forceEnable = true;
  secondDeck[secondIdx].forceEnable = true;

  const swap = firstDeck[firstIdx];
  firstDeck[firstIdx] = secondDeck[secondIdx];
  secondDeck[secondIdx] = swap;
};

const applyBarter = ({ G, payload }) => {
  const { pirateId } = payload;

  const deck = getDeckForPirateId(G, pirateId);

  const pirate = deck.find((p) => p.id === pirateId);

  pirate.cost -= 1;
};

const applyYohoho = ({ G, playerId }) => {
  const player = G.players[playerId];

  player.doubloons += 2;
};

const applyXMarksTheSpot = ({ G, playerId }) => {
  const player = G.players[playerId];

  player.doubloons += 1;
};

const applyWalkThePlank = ({ G, payload }) => {
  const { pirateId } = payload;

  for (const playerId of Object.keys(G.players)) {
    const player = G.players[playerId];
    const pirate = player.pirates.find((p) => p.id === pirateId);
    if (pirate) {
      takeCardFromHandById(player.pirates, pirateId);
      player.doubloons += 2;
      return;
    }
  }
};

const applyCoffer = ({ G, playerId }) => {
  const drawnCards = takeCardsFromDeck(G, 2, "action");

  G.players[playerId].cards.push(...drawnCards);
};

const applyCommandeerPirate = ({ G, playerId, payload }) => {
  const { pirateId, piratePayload } = payload;

  const cardType = CARD_ID_TO_TYPE[pirateId];
  applyCardEffectReducer({ G, cardType, playerId, payload: piratePayload });

  Object.values(G.players).forEach((player) => {
    player.pirates.forEach((pirate) => {
      if (pirate.type === cardType) {
        pirate.played = true;
      }
    });
  });
};

const applyFireInTheHole = ({ G, playerId, payload }) => {
  const { cardId, cardPayload } = payload;

  const cardType = CARD_ID_TO_TYPE[cardId];
  applyCardEffectReducer({ G, cardType, playerId, payload: cardPayload });
};

const applyPillage = ({ G, playerId, payload }) => {
  const { onPlayerId } = payload;

  // TODO backend mary read validation

  G.players[playerId].doubloons += 1;
  G.players[onPlayerId].doubloons -= 1;
};

const applyScallywag = ({ G, playerId }) => {
  const drawnPirates = takeCardsFromDeck(G, 1, "pirate");

  G.players[playerId].pirates.push(...drawnPirates);
};

const applyPlunder = ({ G, playerId, payload }) => {
  const { onPlayerId } = payload;

  // TODO backend grace o malley validation

  const handToStealFrom = G.players[onPlayerId].cards;
  //steal a random card from the chosen player
  const stolenCard = handToStealFrom.splice(
    Math.floor(Math.random() * handToStealFrom.length),
    1
  );
  G.players[playerId].cards.push(stolenCard[0]);
};

const applyMarooned = ({ G, playerId, payload }) => {
  const { onPlayerId } = payload;
  const piratesToDisable = G.players[onPlayerId].pirates;
  //disable all pirates in player's hand
  for (const pirate of piratesToDisable) {
    pirate.enabled = false;
  }
};

// Pirate card effects
const applyAnneBonny = ({ G, playerId }) => {
  const player = G.players[playerId];

  player.doubloons += 1;
};

const applyBartholomewRoberts = ({ G, playerId, payload }) => {
  const { pirateId } = payload;
  const deck = getDeckForPirateId(G, pirateId);
  const replacementPirate = takeCardsFromDeck(G, 1, "pirate");
  const selectedPirate = deck.find((p) => p.id === pirateId);
  const isRightPirate = (pirate) => pirate === selectedPirate;
  const idxOfPirate = G.pirateBoard.findIndex(isRightPirate);
  const freePirate = G.pirateBoard.splice(idxOfPirate, 1);

  G.pirateBoard.push(...replacementPirate);
  G.players[playerId].pirates.push(...freePirate);
};

const applyWilliamKid = ({ G, playerId, payload }) => {
  // const { actionId, actionPayload } = payload;

  // const cardType = CARD_ID_TO_TYPE[actionId];
  // applyCardEffectReducer({ G, cardType, playerId, payload: actionPayload });

  const { actionId, actionPayload } = payload;

  const cardType = CARD_ID_TO_TYPE[actionId];
  applyCardEffectReducer({ G, cardType, playerId, payload: actionPayload });
};

const applyBlackbeard = ({ G, payload }) => {
  const { pirateId } = payload;

  for (const playerId of Object.keys(G.players)) {
    const player = G.players[playerId];
    const pirate = player.pirates.find((p) => p.id === pirateId);
    if (pirate) {
      pirate.enabled = false;
      return;
    }
  }
};

const applyBlackCaesar = ({ G, playerId }) => {
  const drawnCards = takeCardsFromDeck(G, 1, "action");

  G.players[playerId].cards.push(...drawnCards);
};

const applyKanhojiAngre = ({ G, playerId }) => {
  if (G.players[playerId].cards.length < 4) {
    const drawnCards = takeCardsFromDeck(G, 2, "action");
    G.players[playerId].cards.push(...drawnCards);
  }
};

const applyChingShih = ({ G, playerId, payload }) => {
  const { actionToDiscard, onPlayerId } = payload;

  const player = G.players[playerId];

  takeCardFromHandById(player.cards, actionToDiscard);

  player.doubloons += 1;
  G.players[onPlayerId].doubloons -= 1;
};

const applySayyidaAlHurra = ({ G, playerId, payload }) => {
  const { actionToDiscard } = payload;

  const player = G.players[playerId];

  takeCardFromHandById(player.cards, actionToDiscard);

  player.doubloons += 1;
};

const applyBarbarossa = ({ G, playerId, payload }) => {
  const { actionToDiscard } = payload;

  const player = G.players[playerId];

  takeCardFromHandById(player.cards, actionToDiscard);

  const drawnCards = takeCardsFromDeck(G, 2, "action");
  G.players[playerId].cards.push(...drawnCards);
};

const applyRachelWall = ({ G, playerId }) => {
  if (G.players[playerId].cards.length < 5) {
    const drawnCards = takeCardsFromDeck(G, 2, "action");
    G.players[playerId].cards.push(...drawnCards);
  }
};

const applyHenryMorgan = ({ G, playerId, payload }) => {
  const { onPlayerId } = payload;
  const handToStealFrom = G.players[onPlayerId].cards;
  //steal a random card from the chosen player
  const stolenCard = handToStealFrom.splice(
    Math.floor(Math.random() * handToStealFrom.length),
    1
  );
  G.players[playerId].cards.push(stolenCard[0]);
};

const applyCheungPoTsai = ({ G, playerId }) => {
  const drawnCards = takeCardsFromDeck(G, 3, "action");

  G.players[playerId].cards.push(...drawnCards);
  G.players[playerId].doubloons -= 1;
};

const applySamuelBellamy = ({ G, playerId }) => {
  const drawnPirates = takeCardsFromDeck(G, 1, "pirate");

  G.players[playerId].pirates.push(...drawnPirates);
};

// When you implement a new card, add it here
const cardEffectReducer = {
  [ACTIONS.mutiny]: applyMutiny,
  [ACTIONS.yohoho]: applyYohoho,
  [ACTIONS.xmarksthespot]: applyXMarksTheSpot,
  [ACTIONS.walktheplank]: applyWalkThePlank,
  [ACTIONS.coffer]: applyCoffer,
  [ACTIONS.fireinthehole]: applyFireInTheHole,
  [ACTIONS.commandeer]: applyCommandeerPirate,
  [ACTIONS.pillage]: applyPillage,
  [ACTIONS.scallywag]: applyScallywag,
  [ACTIONS.plunder]: applyPlunder,
  [ACTIONS.marooned]: applyMarooned,
  [ACTIONS.barter]: applyBarter,

  [PIRATES.annebonny]: applyAnneBonny,
  [PIRATES.blackbeard]: applyBlackbeard,
  [PIRATES.blackcaesar]: applyBlackCaesar,
  [PIRATES.barbarossa]: applyBarbarossa,
  [PIRATES.chingshih]: applyChingShih,
  [PIRATES.sayyidaalhurra]: applySayyidaAlHurra,
  [PIRATES.kanhojiangre]: applyKanhojiAngre,
  [PIRATES.bartholomewroberts]: applyBartholomewRoberts,
  [PIRATES.rachelwall]: applyRachelWall,
  [PIRATES.henrymorgan]: applyHenryMorgan,
  [PIRATES.cheungpotsai]: applyCheungPoTsai,
  [PIRATES.samuelbellamy]: applySamuelBellamy,
  [PIRATES.hendrickquintor]: applyCommandeerPirate,
  [PIRATES.williamkid]: applyWilliamKid,
};

/*
  This function "applies" an action or pirate card to the game
  It's responsible making all the appropriate state changes
*/
export const applyCardEffectReducer = ({ G, cardType, playerId, payload }) => {
  const applyFn = cardEffectReducer[cardType];
  if (applyFn) {
    applyFn({ G, cardType, playerId, payload });
    return;
  }

  if (ACTION_CARD_TYPES.has(cardType)) {
    console.warn(`${cardType} has not been implemented`);
    return;
  }

  if (PIRATE_CARD_TYPES.has(cardType)) {
    console.warn(`${cardType} has not been implemented`);
    return;
  }

  console.error(`${cardType} is not a card`);
};
