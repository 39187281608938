import React, { useState, useEffect } from "react";
import Header from "../components/header";
import firebase from "../firebase";
import "./GamesList.css";

export default function GamesList({ history }) {
  const [games, setGames] = useState(null);
  const [gamesLoading, setGamesLoading] = useState(true);
  const [players, setPlayers] = useState(null);
  const [playersLoading, setPlayersLoading] = useState(true);
  const [error, setError] = useState(null);

  const playersList = (players) => {
    return players.map((p) => p.nickname).join(", ");
  };

  const fetchGames = () => {
    const unsubscribe = firebase.db
      .collection("games")
      .where("status", "==", "joinable")
      .onSnapshot(
        (snapshot) => {
          const games = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setGames(games);
          setGamesLoading(false);
        },
        (err) => {
          setError(err);
        }
      );

    return () => unsubscribe();
  };

  const fetchPlayers = () => {
    const unsubscribe = firebase.db.collection("players").onSnapshot(
      (snapshot) => {
        const players = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPlayers(players);
        setPlayersLoading(false);
      },
      (err) => {
        setError(err);
      }
    );

    return () => unsubscribe();
  };

  useEffect(fetchGames, []);
  useEffect(fetchPlayers, []);

  error && console.log(error);

  return (
    !gamesLoading &&
    !playersLoading && (
      <>
        <Header />
        <div className="List-content">
          <h1 className="List-title">Find Game</h1>
          <div className="">
            {games.map((game) => {
              const joinRoom = () => {
                history.push(`/game/${game.code}`);
              };
              return (
                <div className="List-item" key={game.code}>
                  Join&nbsp;
                  <span className="List-code" onClick={joinRoom}>
                    {game.code} [{game.numPlayers} Players]
                  </span>
                  <span>&nbsp;with&nbsp;</span>
                  <span className="List-players">
                    {playersList(
                      players.filter((p) =>
                        Object.keys(game.players).includes(p.uid)
                      )
                    )}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </>
    )
  );
}
